import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TutorialAction from '../../../../Services/Redux/Tutorial/TutorialAction';
import { DGTutorialStore } from '../../../../Services/Tutorial/DGTutorialStore';
import { Carousel } from '../../Components/Carousel/Carousel';
import { ImageGuess } from '../../Components/ImageGuess/ImageGuess';
import { PageLayout } from '../../Components/Layouts/PageLayout/PageLayout';
import { TutorialSection } from '../../Components/TutorialSection/TutorialSection';

export const TutorialPage: FunctionComponent = () => {
  const tutorialStore: DGTutorialStore = new DGTutorialStore();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function didComplete(): void {
    dispatch(TutorialAction.completeTutorial(true));
    navigate('/');
  }

  return (
    <PageLayout>
      <div className="space-y-4 place-content-evenly v-stack grow">
        <div className="items-center text-center v-stack">
          <h1 className="text-title">Bienvenue sur le Dall•e Guesser !</h1>
          <h2 className="text-subtitle text-secondary">Voici quelques conseils pour jouer</h2>
        </div>

        <Carousel
          className="w-full max-w-lg px-6 py-4 bg-secondary dark:bg-neutral-800/30 rounded-xl h-[550px] lg:h-[600px]"
          onComplete={didComplete}
        >
          <TutorialSection
            className="grow"
            description="Voici une image générée par Dall-e. À vous de retrouver le texte utilisé pour la générer."
          >
            <img className="w-full max-w-sm rounded-lg shadow-xl" src="/images/tutorial-image.jpeg" />
          </TutorialSection>

          <TutorialSection className="grow" description="Les mots que vous devinez apparaissent en vert.">
            <div className="items-center space-y-4 v-stack">
              <img className="w-full max-w-sm rounded-lg shadow-xl" src="/images/tutorial-image.jpeg" />
              <ImageGuess prefix="Une photo" words={tutorialStore.wordsWithGuessed} />
            </div>
          </TutorialSection>

          <TutorialSection className="grow" description="Aidez-vous des suggestions en orange pour trouver les mots.">
            <div className="items-center space-y-4 v-stack">
              <img className="w-full max-w-sm rounded-lg shadow-xl" src="/images/tutorial-image.jpeg" />
              <ImageGuess prefix="Une photo" words={tutorialStore.wordsWithSuggestions} />
            </div>
          </TutorialSection>
        </Carousel>
      </div>
    </PageLayout>
  );
};
