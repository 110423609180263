/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { catchError, concat, filter, map, mergeMap, Observable, of } from 'rxjs';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { Language } from '../../../Models/Enums/Language';
import { SubmitState } from '../../../Models/Enums/SubmitState';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import { WordServer } from '../../Api/Word/WordServer';
import AnalyticsAction from '../Analytics/AnalyticsAction';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import InputHistoryAction from '../InputHistory/InputHistoryAction';
import { preferredLanguage } from '../Preferences/Utils/PreferencesUtils';
import RoundAction from '../Round/RoundAction';
import GameAction from './GameAction';

function submitWord$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(GameAction.submitWord.match),
    mapPayload(),
    map((input) => ({ input, words: state$.value.game.words, language: preferredLanguage(state$.value) })),
    mergeMap(({ input, words, language }) =>
      concat(
        of(GameAction.clearField()),
        of(GameAction.setSubmitState({ state: SubmitState.loading })),
        submit$(input, words, language, dependencies.wordServer!),
        of(AnalyticsAction.trackEvent({ category: 'game', action: 'submitWord' }))
      )
    )
  );
}

function submit$(input: string, words: DGWord[], language: Language, wordServer: WordServer): Observable<AnyAction> {
  return wordServer.submit$(input, words, language).pipe(
    mergeMap((words) =>
      concat(
        of(GameAction.setWords(words)),
        of(RoundAction.addInput({ input, words })),
        of(InputHistoryAction.addInputToHistory(input)),
        of(GameAction.setSubmitState({ state: SubmitState.waitingForInput }))
      )
    ),
    catchError((error) => concat(of(GameAction.setSubmitState({ state: SubmitState.error, error }))))
  );
}

export default combineEpics(submitWord$);
