/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { Optional } from '../../../Utils/Types/Optional';
import { ThemeGameImageState } from './Models/ThemeGameImageState';

const fetchThemeGame = createAction<Optional<string>>('themeGame/fetchThemeGame');
const setNextImageStateIfNeeded = createAction('themeGame/setNextStateIfNeeded');
const setImageState = createAction<ThemeGameImageState>('themeGame/setImageState');

export default {
  fetchThemeGame,
  setNextImageStateIfNeeded,
  setImageState
};
