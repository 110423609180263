/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import { GameHint } from '../../../Models/Enums/GameHint';
import GameAction from '../Game/GameAction';
import ThemeGameAction from '../ThemeGame/ThemeGameAction';
import GameHintAction from './GameHintAction';
import { GameHintState } from './GameHintState';

const initialState: GameHintState = {
  availableHints: [GameHint.revealFirstLetter]
};
export const gameHintReducer = createReducer<GameHintState>(initialState, (builder) => {
  builder.addCase(GameHintAction.resetGameHints, (state) => ({
    ...state,
    availableHints: initialState.availableHints,
    selectedHint: undefined
  }));
  builder.addCase(GameHintAction.removeGameHint, (state, action) => ({
    ...state,
    availableHints: state.availableHints.filter((hint) => hint !== action.payload)
  }));
  builder.addCase(GameHintAction.selectGameHint, (state, action) => ({
    ...state,
    selectedHint: action.payload
  }));
  builder.addCase(GameHintAction.deselectGameHint, (state) => ({
    ...state,
    selectedHint: undefined
  }));

  builder.addCase(GameAction.setGame, () => initialState);
  builder.addCase(ThemeGameAction.setImageState, () => initialState);
  builder.addCase(GameAction.submitWord, (state) => ({ ...state, selectedHint: undefined }));
  builder.addCase(GameAction.setGameFromSave, (state, action) => ({
    ...state,
    ...action.payload.gameHint,
    selectedHint: undefined
  }));
});
