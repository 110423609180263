import { FunctionComponent } from 'react';
import { Oval } from 'react-loader-spinner';

export const LoadingIndicator: FunctionComponent = () => {
  return (
    <div className="v-stack items-center">
      <Oval width={35} height={35} color="#4E80EE" secondaryColor="#4E80EE50" strokeWidth={4} />
      <div className="text-gray-500 dark:text-neutral-500">Chargement...</div>
    </div>
  );
};
