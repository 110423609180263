import classNames from 'classnames';
import { FunctionComponent } from 'react';

interface SubmitButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const SubmitButton: FunctionComponent<SubmitButtonProps> = ({ className, disabled = false, onClick }) => {
  return (
    <button
      className={classNames(
        'px-4 rounded-lg transition ease-in-out',
        'text-blue-500',
        'hover:bg-blue-500 hover:bg-opacity-20',
        'active:opacity-20',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      Envoyer
    </button>
  );
};
