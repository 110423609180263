import { ThemeGameImageState } from '../../../Services/Redux/ThemeGame/Models/ThemeGameImageState';
import { DGImage } from '../DGImage';

export class DGGameThemeData {
  public constructor(
    public date: Date,
    public image1: DGImage,
    public image2: DGImage,
    public image3: DGImage,
    public image4: DGImage,
    public theme: string,
    public referenceUrl?: string
  ) {}

  public get images(): DGImage[] {
    return [this.image1, this.image2, this.image3, this.image4];
  }

  public imageForState(imageState: ThemeGameImageState): DGImage {
    switch (imageState) {
      case ThemeGameImageState.image1:
        return this.image1;
      case ThemeGameImageState.image2:
        return this.image2;
      case ThemeGameImageState.image3:
        return this.image3;
      case ThemeGameImageState.image4:
        return this.image4;
      default:
        return this.image1;
    }
  }
}
