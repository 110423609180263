import { DGWord } from '../../Models/App/Word/DGWord';
import { DGWordSuggestion } from '../../Models/App/Word/DGWordSuggestion';
import { WordState } from '../../Models/Enums/WordState';
import { TutorialStore } from './TutorialStore';

export class DGTutorialStore implements TutorialStore {
  public wordsWithGuessed: DGWord[] = [
    DGWord.withText("d'un", WordState.recentlyGuessed),
    DGWord.withText('petit', WordState.notGuessed),
    DGWord.withText('chat', WordState.recentlyGuessed),
    DGWord.withText('marchant', WordState.notGuessed),
    DGWord.withText('sur', WordState.recentlyGuessed),
    DGWord.withText('une', WordState.recentlyGuessed),
    DGWord.withText('table', WordState.recentlyGuessed)
  ];
  public wordsWithSuggestions: DGWord[] = [
    DGWord.withText("d'un", WordState.guessed),
    DGWord.withText('petit', WordState.recentlySuggested, new DGWordSuggestion('grand', 0.6)),
    DGWord.withText('chat', WordState.guessed),
    DGWord.withText('marchant', WordState.recentlySuggested, new DGWordSuggestion('marcher', 0.6)),
    DGWord.withText('sur', WordState.guessed),
    DGWord.withText('une', WordState.guessed),
    DGWord.withText('table', WordState.guessed)
  ];
}
