import anime from 'animejs';
import { FunctionComponent, useEffect } from 'react';
import { WordContainer } from './Components/WordContainer';
import { WordPrefix } from './Components/WordPrefix';
import { WordSuffix } from './Components/WordSuffix';

interface Props {
  text: string;
  suggestion: string;
  isSelectingHintWord: boolean;
  onClick: () => void;
}

export const WordRecentlySuggested: FunctionComponent<Props> = ({ text, suggestion, isSelectingHintWord, onClick }) => {
  const characters: string[] = suggestion.split('');

  useEffect(() => {
    anime({
      targets: '.recently-suggested-letter',
      opacity: [0, 1],
      delay: anime.stagger(20)
    });
  }, [text]);

  return (
    <div className="h-stack">
      <WordPrefix text={text} />
      <WordContainer isSelectingHintWord={isSelectingHintWord} onClick={onClick}>
        <div className="inline-block px-1 text-orange-500 h-stack">
          {characters.map((character, index) => (
            <div className="recently-suggested-letter" key={index}>
              {character}
            </div>
          ))}
        </div>
      </WordContainer>
      <WordSuffix text={text} />
    </div>
  );
};
