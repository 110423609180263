import { Type } from 'class-transformer';
import { DGImage } from '../DGImage';

export class DGGameDailyData {
  @Type(() => DGImage)
  public readonly image: DGImage;
  public readonly date: Date;

  public constructor(date: Date, image: DGImage) {
    this.date = date;
    this.image = image;
  }
}
