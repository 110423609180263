import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Direction } from '../../../../../Models/Enums/Direction';
import { Chevron } from '../../Svg/Chevron/Chevron';
import { CustomButton } from '../CustomButton/CustomButton';

interface NavigationButtonProps {
  className?: string;
  direction?: Direction;
  navigation: string;
}

export const NavigationButton: FunctionComponent<PropsWithChildren<NavigationButtonProps>> = ({
  className,
  children,
  direction = Direction.right,
  navigation
}) => {
  const navigate = useNavigate();

  function navigateToPage(): void {
    navigate(navigation);
  }

  return (
    <CustomButton
      onClick={navigateToPage}
      className={classNames('py-4', direction === Direction.right ? 'pl-2 pr-0.5' : 'pl-0.5 pr-2', className)}
    >
      <div className="items-center justify-between h-stack">
        {direction === Direction.left && <Chevron direction={Direction.left} />}

        <div className={classNames('h-stack items-center grow', direction === Direction.right ? 'mr-auto' : 'ml-auto')}>
          {children}
        </div>

        {direction === Direction.right && <Chevron direction={Direction.right} />}
      </div>
    </CustomButton>
  );
};
