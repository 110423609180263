import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { DGTip } from '../../../../../../Models/App/DGTip';

export const Tips: FunctionComponent = () => {
  const allTips: DGTip[] = [
    {
      title: 'Les mots doux',
      description: 'Essayer des mots comme le, la, les, de, du, des, peut aider à trouver des mots plus compliqués.'
    },
    {
      title: 'Regarder le ciel',
      description: 'Il faut beau ici ? Pensez à regarder la météo sur les images.'
    },
    {
      title: 'Un pas en avant',
      description: 'La position des objets a son importance. Essayez des mots comme devant, derrière, à coté, autour.'
    },
    {
      title: 'Œil de lynx',
      description: "Pour commencer, décrivez les objets et paysages que vous voyez à l'image."
    },
    {
      title: 'Conditions optimales',
      description:
        'Manger un bon morceau de fromage aide à stimuler votre cerveau avant une partie de Dall-E Guesser. (vraiment)'
    }
  ];
  const [randomTip, _] = useState(allTips[Math.floor(Math.random() * allTips.length)]);

  const LightBackground: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
      <div
        className="items-center justify-center w-full h-full p-8 dark:hidden v-stack"
        style={{
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23fffff' fill-opacity='0.15' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E\")"
        }}
      >
        {children}
      </div>
    );
  };

  const DarkBackground: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
      <div
        className="items-center justify-center hidden h-full p-8 dark:v-stack"
        style={{
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23d4d4d4' fill-opacity='0.1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E\")"
        }}
      >
        {children}
      </div>
    );
  };

  const Tip: FunctionComponent<{ tip: DGTip }> = ({ tip }) => {
    return (
      <div className="max-w-sm space-y-6">
        <h2 className="text-2xl font-medium text-secondary">{tip.title}</h2>
        <h3 className="text-justify text-secondary">{tip.description}</h3>
      </div>
    );
  };

  return (
    <div className="h-full v-stack">
      <LightBackground>
        <Tip tip={randomTip} />
      </LightBackground>
      <DarkBackground>
        <Tip tip={randomTip} />
      </DarkBackground>
    </div>
  );
};
