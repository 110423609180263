import { Type } from 'class-transformer';
import { ToApiModelConvertible } from 'model-conversion';
import { nanoid } from 'nanoid';
import { ApiWord } from '../../Api/Word/ApiWord';
import { WordState } from '../../Enums/WordState';
import { DGWordSuggestion } from './DGWordSuggestion';

export class DGWord implements ToApiModelConvertible<ApiWord> {
  @Type(() => DGWordSuggestion)
  public suggestion?: DGWordSuggestion;
  public readonly id: string;
  public readonly text: string;
  public state: WordState;
  public revealedLetters: number = 0;

  public constructor(
    id: string,
    text: string,
    state: WordState,
    suggestion?: DGWordSuggestion,
    revealedLetters: number = 0
  ) {
    this.id = id;
    this.text = text;
    this.state = state;
    this.suggestion = suggestion;
    this.revealedLetters = revealedLetters;
  }

  public get isGuessed(): boolean {
    switch (this.state) {
      case WordState.guessed:
      case WordState.recentlyGuessed:
        return true;
      default:
        return false;
    }
  }

  public static withText(text: string, state: WordState = WordState.notGuessed, suggestion?: DGWordSuggestion): DGWord {
    return new DGWord(nanoid(), text, state, suggestion);
  }
  public toApiModel(): ApiWord {
    return new ApiWord(this.id, this.text, this.state, this.suggestion?.toApiModel(), this.revealedLetters);
  }
}
