import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { Direction } from '../../../../Models/Enums/Direction';
import { CustomButton } from '../Buttons/CustomButton/CustomButton';
import { Chevron } from '../Svg/Chevron/Chevron';

interface CarouselControlProps {
  index: number;
  length: number;
  isInfinite?: boolean;
  onIndexChange: (index: number) => void;
  onComplete?: () => void;
}

export const CarouselControl: FunctionComponent<CarouselControlProps> = ({
  index,
  length,
  isInfinite = false,
  onIndexChange,
  onComplete
}) => {
  function didSelectIndex(index: number): void {
    onIndexChange(index);
  }

  function nextIndex(): void {
    if (index < length - 1) {
      onIndexChange(index + 1);
    } else if (isInfinite) {
      onIndexChange(0);
    } else if (onComplete) {
      onComplete();
    }
  }

  function previousIndex(): void {
    if (index > 0) {
      onIndexChange(index - 1);
    } else if (isInfinite) {
      onIndexChange(length - 1);
    }
  }

  return (
    <div className="grid grid-cols-3 grid-rows-1">
      <div className="items-start justify-center v-stack">
        <CustomButton disabled={!isInfinite && index === 0} onClick={previousIndex}>
          <Chevron className="m-1.5" direction={Direction.left} />
        </CustomButton>
      </div>

      <div className="items-center justify-center space-x-3 h-stack">
        {Array(length)
          .fill(0)
          .map((_, i) => (
            <button
              key={i}
              className={classNames('w-2 h-2 rounded-full', i === index ? 'bg-control' : 'bg-tertiary')}
              onClick={(): void => didSelectIndex(i)}
            />
          ))}
      </div>

      <div className="items-end justify-center v-stack">
        <CustomButton disabled={!isInfinite && !onComplete && index === length - 1} onClick={nextIndex}>
          <Chevron className="m-1.5" direction={Direction.right} />
        </CustomButton>
      </div>
    </div>
  );
};
