import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DGGame } from '../../../../Models/App/Game/DGGame';
import GameSelector from '../../../../Services/Redux/Game/GameSelector';
import ThemeGameAction from '../../../../Services/Redux/ThemeGame/ThemeGameAction';
import { Nullable } from '../../../../Utils/Types/Nullable';
import { Optional } from '../../../../Utils/Types/Optional';
import { Game } from '../../Components/Game/Game';
import { PageLayout } from '../../Components/Layouts/PageLayout/PageLayout';
import { LoadingIndicator } from '../../Components/LoadingIndicator/LoadingIndicator';
import { Case } from '../../Components/Switch/Case';
import { Switch } from '../../Components/Switch/Switch';

export const ThemeGamePage: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state: string = useSelector(GameSelector.state);
  const game: Optional<DGGame> = useSelector(GameSelector.game);
  const hasWon: boolean = useSelector(GameSelector.hasWon);

  useEffect(() => {
    const gameId: Nullable<string> = searchParams.get('id');
    if (gameId) {
      dispatch(ThemeGameAction.fetchThemeGame(gameId));
    } else {
      dispatch(ThemeGameAction.fetchThemeGame());
    }
  }, [searchParams]);

  useEffect(() => {
    if (hasWon) {
      navigate('/theme/win', { replace: true });
    }
  }, [hasWon]);

  return (
    <PageLayout>
      <Switch value={state}>
        <Case value="loading">
          <LoadingIndicator />
        </Case>
        <Case value="data">
          <Game game={game!} />
        </Case>
        <Case value="error">
          <div>Failed to load theme game</div>
        </Case>
      </Switch>
    </PageLayout>
  );
};
