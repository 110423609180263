import { FunctionComponent, PropsWithChildren } from 'react';
import { Footer } from '../../Footer/Footer';
import { NavigationBar } from '../../NavigationBar/NavigationBar';

interface PageLayoutProps {
  navigationBar?: boolean;
  stickyNavigationBar?: boolean;
}

export const PageLayout: FunctionComponent<PropsWithChildren<PageLayoutProps>> = ({
  children,
  navigationBar = true,
  stickyNavigationBar = false
}) => {
  return (
    <div className="items-center px-4 pb-4 lg:px-10 v-stack grow">
      {navigationBar && (
        <div className="w-full pb-4">
          <NavigationBar sticky={stickyNavigationBar} />
        </div>
      )}

      <div className="items-center w-full max-w-7xl v-stack grow">{children}</div>

      <Footer />
    </div>
  );
};
