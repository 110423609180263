import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { concat, filter, mergeMap, Observable, of } from 'rxjs';
import { GameType } from '../../../Models/Enums/GameType';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import GameAction from '../Game/GameAction';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import SaveAction from '../Save/SaveAction';
import StateAction from '../State/StateAction';
import DailyGameAction from './DailyGameAction';

function fetchDailyGame$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  _dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(DailyGameAction.fetchDailyGame.match),
    mapPayload(),
    mergeMap((gameId) =>
      concat(
        StateAction.setLoading(GameAction.statePrefix),
        gameId ? of(SaveAction.loadGameWithId(gameId)) : of(SaveAction.loadGameOfType(GameType.daily))
      )
    )
  );
}

export default combineEpics(fetchDailyGame$);
