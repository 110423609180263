import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, map, Observable, tap } from 'rxjs';
import Action from '../../../Utils/Action';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import TutorialAction from './TutorialAction';

function loadCompletedTutorial$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(Action.start.match),
    map(() => dependencies.saveSystem?.hasCompletedTutorial ?? false),
    map((hasCompletedTutorial) => TutorialAction.completeTutorial(hasCompletedTutorial))
  );
}

function setCompletedTutorial$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(TutorialAction.completeTutorial.match),
    mapPayload(),
    tap((hasCompletedTutorial) => dependencies.saveSystem?.setHasCompletedTutorial(hasCompletedTutorial)),
    map((hasCompletedTutorial) => TutorialAction.setHasCompletedTutorial(hasCompletedTutorial))
  );
}

export default combineEpics(loadCompletedTutorial$, setCompletedTutorial$);
