import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DGWord } from '../../../../Models/App/Word/DGWord';
import { WordState } from '../../../../Models/Enums/WordState';
import GameHintAction from '../../../../Services/Redux/GameHint/GameHintAction';
import GameHintSelector from '../../../../Services/Redux/GameHint/GameHintSelector';
import { Case } from '../Switch/Case';
import { Switch } from '../Switch/Switch';
import { WordGuessed } from './WordGuessed';
import { WordRecentlyHinted } from './WordHinted';
import { WordNotGuessed } from './WordNotGuessed';
import { WordRecentlyGuessed } from './WordRecentlyGuessed';
import { WordRecentlySuggested } from './WordRecentlySuggested';
import { WordSuggested } from './WordSuggested';

interface WordProps {
  word: DGWord;
}

export const Word: FunctionComponent<WordProps> = ({ word }) => {
  const dispatch = useDispatch();
  const isSelectingWord: boolean = useSelector(GameHintSelector.isSelectingWord);

  function applyHint(): void {
    if (!isSelectingWord) {
      return;
    }
    dispatch(GameHintAction.applyHintOnWord(word));
  }

  return (
    <Switch value={word.state}>
      <Case value={WordState.recentlyGuessed}>
        <WordRecentlyGuessed text={word.text} />
      </Case>
      <Case value={WordState.guessed}>
        <WordGuessed text={word.text} />
      </Case>
      <Case value={WordState.recentlySuggested}>
        <WordRecentlySuggested
          text={word.text}
          suggestion={word.suggestion?.text ?? ''}
          isSelectingHintWord={isSelectingWord}
          onClick={applyHint}
        />
      </Case>
      <Case value={WordState.suggested}>
        <WordSuggested
          text={word.text}
          suggestion={word.suggestion?.text ?? ''}
          similarity={word.suggestion?.similarity ?? 1}
          isSelectingHintWord={isSelectingWord}
          onClick={applyHint}
        />
      </Case>
      <Case value={WordState.revealFirstLetter}>
        <WordRecentlyHinted
          text={word.text}
          lettersToReveal={word.revealedLetters}
          isSelectingHintWord={isSelectingWord}
          onClick={applyHint}
        />
      </Case>
      <Case value={WordState.notGuessed}>
        <WordNotGuessed text={word.text} isSelectingHintWord={isSelectingWord} onClick={applyHint} />
      </Case>
    </Switch>
  );
};
