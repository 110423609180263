/* eslint-disable @typescript-eslint/typedef */
import { AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware, EpicMiddleware } from 'redux-observable';
import AnalyticsEpic from '../Analytics/AnalyticsEpic';
import ArchivesEpic from '../Archives/ArchivesEpic';
import { archivesReducer } from '../Archives/ArchivesReducer';
import DailyGameEpic from '../DailyGame/DailyGameEpic';
import GameEpic from '../Game/GameEpic';
import { gameReducer } from '../Game/GameReducer';
import GameHintEpic from '../GameHint/GameHintEpic';
import { gameHintReducer } from '../GameHint/GameHintReducer';
import { inputHistoryReducer } from '../InputHistory/InputHistoryReducer';
import { preferencesReducer } from '../Preferences/PreferencesReducer';
import { roundReducer } from '../Round/RoundReducer';
import SaveEpics from '../Save/SaveEpic';
import ThemeGameEpic from '../ThemeGame/ThemeGameEpic';
import TutorialEpic from '../Tutorial/TutorialEpic';
import { tutorialReducer } from '../Tutorial/TutorialReducer';
import { StoreDependencies } from './StoreDependencies';
import { StoreState } from './StoreState';

export function createStore(dependencies: Partial<StoreDependencies>, preloadedState?: StoreState): Store<StoreState> {
  const epicMiddleware: EpicMiddleware<
    AnyAction,
    AnyAction,
    StoreState,
    Partial<StoreDependencies>
  > = createEpicMiddleware({
    dependencies
  });
  const rootEpic = combineEpics<AnyAction, AnyAction, StoreState, Partial<StoreDependencies>>(
    GameEpic,
    GameHintEpic,
    DailyGameEpic,
    ThemeGameEpic,
    ArchivesEpic,
    SaveEpics,
    TutorialEpic,
    AnalyticsEpic
  );
  const store: Store<StoreState> = configureStore({
    reducer: {
      preferences: preferencesReducer,
      game: gameReducer,
      gameHint: gameHintReducer,
      inputHistory: inputHistoryReducer,
      round: roundReducer,
      archives: archivesReducer,
      tutorial: tutorialReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [epicMiddleware],
    preloadedState
  });
  epicMiddleware.run(rootEpic);
  return store;
}
