/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import GameAction from '../Game/GameAction';
import ThemeGameAction from '../ThemeGame/ThemeGameAction';
import InputHistoryAction from './InputHistoryAction';
import { InputHistoryState } from './InputHistoryState';

const initialState: InputHistoryState = {
  inputHistory: [],
  inputCount: 0
};
export const inputHistoryReducer = createReducer<InputHistoryState>(initialState, (builder) => {
  builder.addCase(InputHistoryAction.addInputToHistory, (state, action) => ({
    ...state,
    inputHistory: [...state.inputHistory, ...inputWordsFromInput(action.payload)],
    inputCount: state.inputCount + 1
  }));
  builder.addCase(GameAction.setGame, () => initialState);
  builder.addCase(ThemeGameAction.setImageState, () => initialState);
  builder.addCase(GameAction.setGameFromSave, (state, action) => ({
    ...state,
    ...action.payload.inputHistory
  }));
});

function inputWordsFromInput(input: string): string[] {
  return input.split(' ');
}
