import { FunctionComponent } from 'react';
import { DGGame } from '../../../../Models/App/Game/DGGame';
import { GameType } from '../../../../Models/Enums/GameType';
import { Case } from '../Switch/Case';
import { Switch } from '../Switch/Switch';
import { DailyGame } from './Daily/DailyGame';
import { ThemeGame } from './Theme/ThemeGame';

interface GameProps {
  game: DGGame;
}

export const Game: FunctionComponent<GameProps> = ({ game }) => {
  return (
    <Switch value={game.type}>
      <Case value={GameType.daily}>
        <DailyGame />
      </Case>
      <Case value={GameType.theme}>
        <ThemeGame />
      </Case>
    </Switch>
  );
};
