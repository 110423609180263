import { FunctionComponent } from 'react';
import { rangeArray } from '../../../../../../../Utils/Array';
import { Optional } from '../../../../../../../Utils/Types/Optional';

interface ThemeGuessedImagesProps {
  guessedImageUrls: string[];
}

export const ThemeGuessedImages: FunctionComponent<ThemeGuessedImagesProps> = ({ guessedImageUrls }) => {
  return (
    <div className="grid grid-cols-4 gap-3">
      {rangeArray(0, 4, 1).map((index) => {
        const imageUrl: Optional<string> = guessedImageUrls[index];
        return imageUrl ? (
          <img key={imageUrl} className="w-full max-w-[50px] rounded-md guessed-image" src={imageUrl} alt="" />
        ) : (
          <div
            key={index}
            className="w-[50px] h-[50px] v-stack items-center justify-center rounded-md bg-secondary text-secondary text-xl font-medium"
          >
            {index + 1}
          </div>
        );
      })}
    </div>
  );
};
