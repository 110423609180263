import { FunctionComponent } from 'react';
import { isPunctuation } from '../../../../../Utils/String';

interface Props {
  text: string;
}

export const WordPrefix: FunctionComponent<Props> = ({ text }) => {
  const characters: string[] = text.split('');

  return <>{isPunctuation(characters[0]) ? characters[0] : undefined}</>;
};
