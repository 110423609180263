import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { Direction } from '../../../../../Models/Enums/Direction';
import { Case } from '../../Switch/Case';
import { Switch } from '../../Switch/Switch';

interface ChevronProps {
  className?: string;
  direction?: Direction;
}

export const Chevron: FunctionComponent<ChevronProps> = ({ className, direction = Direction.right }) => {
  return (
    <div className={classNames('w-6 h-6 stroke-gray-300 dark:stroke-neutral-700', className)}>
      <Switch value={direction}>
        <Case value={Direction.left}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </Case>
        <Case value={Direction.right}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </Case>
      </Switch>
    </div>
  );
};
