import anime from 'animejs';
import { FunctionComponent, useEffect, useState } from 'react';
import { isPunctuation } from '../../../../Utils/String';
import { WordContainer } from './Components/WordContainer';
import { WordSuffix } from './Components/WordSuffix';

interface Props {
  text: string;
  lettersToReveal: number;
  isSelectingHintWord: boolean;
  onClick: () => void;
}

export const WordRecentlyHinted: FunctionComponent<Props> = ({
  text,
  lettersToReveal,
  isSelectingHintWord,
  onClick
}) => {
  const characters: string[] = text.split('');
  const [revealLetterMaxIndex, setRevealLetterMaxIndex] = useState(0);

  useEffect(() => {
    let index: number = 0;
    while (index < lettersToReveal) {
      if (isPunctuation(characters[index])) {
        index++;
        continue;
      }
      index++;
    }
    setRevealLetterMaxIndex(index);
  }, [characters, lettersToReveal]);

  useEffect(() => {
    anime({
      targets: '.recently-hinted-letter',
      scale: [0, 1],
      opacity: [0, 1],
      delay: anime.stagger(20)
    });
  }, [text, lettersToReveal]);

  return (
    <div className="h-stack">
      <WordContainer isSelectingHintWord={isSelectingHintWord} onClick={onClick}>
        <div className="items-center px-1 h-stack">
          {characters.map((character, index) => {
            const isLastCharacter: boolean = index === characters.length - 1;
            if (isLastCharacter && isPunctuation(character)) {
              return undefined;
            }
            if (index < revealLetterMaxIndex) {
              return (
                <div key={index} className="recently-hinted-letter">
                  {character}
                </div>
              );
            }
            return <div key={index} className="w-2 h-2 ml-1 rounded-full bg-tertiary recently-hinted-letter"></div>;
          })}
        </div>
      </WordContainer>
      <WordSuffix text={text} />
    </div>
  );
};
