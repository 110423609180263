import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import AnalyticsAction from '../../../../Services/Redux/Analytics/AnalyticsAction';

interface WikiButtonProps {
  text: string;
  url: string;
}

export const WikiButton: FunctionComponent<WikiButtonProps> = ({ text, url }) => {
  const dispatch = useDispatch();

  function trackWikiEvent(): void {
    dispatch(AnalyticsAction.trackEvent({ category: 'wikipedia', action: 'wikipedia' }));
  }

  return (
    <a
      className="lg:text-xl hover:opacity-50 active:opacity-25"
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={trackWikiEvent}
    >
      <div className="underline">
        {text}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block w-4 h-4 ml-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
        </svg>
      </div>
    </a>
  );
};
