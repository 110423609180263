import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DGRound } from '../../../../Models/App/DGRound';
import { Direction } from '../../../../Models/Enums/Direction';
import { GameType } from '../../../../Models/Enums/GameType';
import DailyGameWinSelector from '../../../../Services/Redux/DailyGameWin/DailyGameWinSelector';
import GameSelector from '../../../../Services/Redux/Game/GameSelector';
import { NavigationButton } from '../../Components/Buttons/NavigationButton/NavigationButton';
import { Confetti } from '../../Components/Confetti/Confetti';
import { GameImage } from '../../Components/GameImage/GameImage';
import { PageLayout } from '../../Components/Layouts/PageLayout/PageLayout';
import { VerticalToHorizontalLayout } from '../../Components/Layouts/VerticalToHorizontalLayout/VerticalToHorizontalLayout';
import { RoundGauge } from '../../Components/RoundGauge/RoundGauge';
import { ShareStack } from '../../Components/ShareStack/ShareStack';

export const DailyGameWinPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const imageUrl: string = useSelector(DailyGameWinSelector.imageUrl);
  const winText: string = useSelector(DailyGameWinSelector.imageText);
  const round: DGRound = useSelector(DailyGameWinSelector.round);
  const hasWon: boolean = useSelector(GameSelector.hasWon);

  useEffect(() => {
    if (!hasWon) {
      navigate('/daily');
    }
  }, [hasWon]);

  return (
    <PageLayout>
      {hasWon ? (
        <VerticalToHorizontalLayout>
          <Confetti />

          <div className="items-center justify-center v-stack">
            <GameImage src={imageUrl} />
          </div>

          <div className="items-center justify-center v-stack">
            <div className="items-center w-full max-w-md space-y-8 v-stack">
              <div className="space-y-2 text-center">
                <h1 className="text-title">Trouvé !</h1>
                <div className="lg:text-xl">{winText}</div>
              </div>

              <div className="items-center w-full space-y-2 v-stack">
                <RoundGauge className="w-full" round={round} />
                <ShareStack gameType={GameType.daily} />
              </div>

              <div className="items-center w-full space-x-4 h-stack">
                <NavigationButton className="grow" navigation="/" direction={Direction.left}>
                  <div className="w-[100px] text-right ml-auto">Retour</div>
                </NavigationButton>

                <div className="w-px h-10 bg-neutral-300 dark:bg-neutral-700"></div>

                <NavigationButton className="grow" navigation="/theme">
                  <div className="w-[100px] text-left">Continuer</div>
                </NavigationButton>
              </div>
            </div>
          </div>
        </VerticalToHorizontalLayout>
      ) : (
        <></>
      )}
    </PageLayout>
  );
};
