import { ToAppModelConvertible } from 'model-conversion';
import { DGGame } from '../../App/Game/DGGame';
import { GameType } from '../../Enums/GameType';
import { ApiGameDailyData } from './ApiGameDailyData';
import { ApiGameThemeData } from './ApiGameThemeData';

export class ApiGame implements ToAppModelConvertible<DGGame> {
  public id: string;
  public type: GameType;
  public data: ApiGameDailyData | ApiGameThemeData;

  private constructor(id: string, type: GameType, data: ApiGameDailyData | ApiGameThemeData) {
    this.id = id;
    this.type = type;
    this.data = data;
  }

  public static fromJson(json: any): ApiGame {
    switch (json.type) {
      case GameType.daily:
        return new ApiGame(json.id, GameType.daily, ApiGameDailyData.fromJson(json.data));
      case GameType.theme:
        return new ApiGame(json.id, GameType.theme, ApiGameThemeData.fromJson(json.data));
      default:
        throw new Error(`${json.type} is unsupported.`);
    }
  }

  public toAppModel(): DGGame {
    return new DGGame(this.id, this.type, this.data.toAppModel());
  }
}
