import { FunctionComponent } from 'react';
import { GameType } from '../../../../../Models/Enums/GameType';
import { Star } from '../../Svg/Star/Star';
import { NavigationButton } from '../NavigationButton/NavigationButton';
import { GameButtonIcon } from './GameButtonIcon';

interface GameInterface {
  className?: string;
  gameType: GameType;
  winCount: number;
}

export const GameButton: FunctionComponent<GameInterface> = ({ className, gameType, winCount }) => {
  function title(): string {
    switch (gameType) {
      case GameType.daily:
        return 'Image du jour';
      case GameType.theme:
        return 'Thème du jour';
    }
  }

  function subtitle(): string {
    switch (gameType) {
      case GameType.daily:
        return 'Une image à deviner';
      case GameType.theme:
        return 'Quatre images et un thème en commun';
    }
  }

  function navigationPath(): string {
    switch (gameType) {
      case GameType.daily:
        return '/daily';
      case GameType.theme:
        return '/theme';
    }
  }

  return (
    <NavigationButton className={className} navigation={navigationPath()}>
      <div className="items-center h-stack grow">
        <div className="shrink-0">
          <GameButtonIcon gameType={gameType} />
        </div>

        <div className="items-start w-full ml-3 v-stack">
          <div className="items-center justify-between w-full h-stack">
            <div className="text-2xl font-medium">{title()}</div>

            <div className="items-center mx-2 mt-1 space-x-1 h-stack">
              <div className="justify-center text-xs font-medium text-yellow-400 v-stack">{winCount}</div>
              <div className="w-3 h-3 fill-yellow-400">
                <Star />
              </div>
            </div>
          </div>

          <div className="leading-tight text-left text-secondary">{subtitle()}</div>
        </div>
      </div>
    </NavigationButton>
  );
};
