/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import TutorialAction from './TutorialAction';
import { TutorialState } from './TutorialState';

const initialState: TutorialState = {
  currentStep: 0,
  hasCompletedTutorial: true
};
export const tutorialReducer = createReducer<TutorialState>(initialState, (builder) => {
  builder.addCase(TutorialAction.setHasCompletedTutorial, (state, action) => ({
    ...state,
    hasCompletedTutorial: action.payload
  }));
});
