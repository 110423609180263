import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

export const VerticalToHorizontalLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={classNames(
        'w-full max-w-md h-full space-y-4 v-stack justify-center grow',
        'lg:max-w-none lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-4'
      )}
    >
      {children}
    </div>
  );
};
