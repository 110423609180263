import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DGWord } from '../../../../../Models/App/Word/DGWord';
import DailyGameSelector from '../../../../../Services/Redux/DailyGame/DailyGameSelector';
import GameAction from '../../../../../Services/Redux/Game/GameAction';
import GameSelector from '../../../../../Services/Redux/Game/GameSelector';
import { State } from '../../../../../Services/Redux/State/State';
import { Optional } from '../../../../../Utils/Types/Optional';
import { GameFlipImage } from '../../GameFlipImage/GameFlipImage';
import { HintSimilarWordButton } from '../../HintSimilarWordButton/HintSimilarWordButton';
import { ImageGuess } from '../../ImageGuess/ImageGuess';
import { VerticalToHorizontalLayout } from '../../Layouts/VerticalToHorizontalLayout/VerticalToHorizontalLayout';
import { SubmitField } from '../../SubmitField/SubmitField';

export const DailyGame: FunctionComponent = () => {
  const dispatch = useDispatch();
  const guessPrefix: Optional<string> = useSelector(DailyGameSelector.guessPrefix);
  const submitState: State = useSelector(GameSelector.submitState);
  const imageUrl: string = useSelector(DailyGameSelector.imageUrl);
  const words: DGWord[] = useSelector(GameSelector.words);
  const fieldValue: string = useSelector(GameSelector.fieldValue);

  function submitWord(word: string): void {
    dispatch(GameAction.submitWord(word));
  }

  function setFieldValue(value: string): void {
    dispatch(GameAction.setFieldValue(value));
  }

  return (
    <VerticalToHorizontalLayout>
      <div className="items-center justify-center v-stack">
        <GameFlipImage className="w-full" src={imageUrl} />
      </div>

      <div className="items-center justify-center v-stack">
        <div className="items-center max-w-md space-y-4 v-stack">
          <ImageGuess prefix={guessPrefix ?? ''} words={words} />
          <HintSimilarWordButton />
          <SubmitField value={fieldValue} state={submitState.state} onChange={setFieldValue} onSubmit={submitWord} />
        </div>
      </div>
    </VerticalToHorizontalLayout>
  );
};
