import { ToAppModelConvertible } from 'model-conversion';
import { DGGameDailyData } from '../../App/Game/DGGameDailyData';
import { ApiImage } from '../ApiImage';

export class ApiGameDailyData implements ToAppModelConvertible<DGGameDailyData> {
  public date: Date;
  public image: ApiImage;

  private constructor(date: Date, image: ApiImage) {
    this.date = date;
    this.image = image;
  }

  public static fromJson(json: any): ApiGameDailyData {
    const image: ApiImage = ApiImage.fromJson(json.image);
    return new ApiGameDailyData(new Date(json.date), image);
  }

  public toAppModel(): DGGameDailyData {
    return new DGGameDailyData(this.date, this.image.toAppModel());
  }
}
