/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnyAction } from '@reduxjs/toolkit';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, concat, filter, map, mergeMap, of, tap } from 'rxjs';
import { DGSave } from '../../../Models/App/DGSave';
import { DGGame } from '../../../Models/App/Game/DGGame';
import { DGGameDailyData } from '../../../Models/App/Game/DGGameDailyData';
import { DGGameThemeData } from '../../../Models/App/Game/DGGameThemeData';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameType } from '../../../Models/Enums/GameType';
import Action from '../../../Utils/Action';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import { Optional } from '../../../Utils/Types/Optional';
import GameAction from '../Game/GameAction';
import { GameState } from '../Game/GameState';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import { preferredLanguage } from '../Preferences/Utils/PreferencesUtils';
import RoundAction from '../Round/RoundAction';
import StateAction from '../State/StateAction';
import { ThemeGameImageState } from '../ThemeGame/Models/ThemeGameImageState';
import SaveAction from './SaveAction';

function save$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(
      (action) =>
        GameAction.setGame.match(action) ||
        GameAction.setWords.match(action) ||
        GameAction.updateWord.match(action) ||
        RoundAction.addInput.match(action)
    ),
    map(() => state$.value),
    filter((state) => state.game.current !== undefined),
    map((state) => ({
      game: state.game,
      gameHint: state.gameHint,
      currentGame: state.game.current!,
      inputHistory: state.inputHistory,
      round: state.round
    })),
    tap(({ game, gameHint, currentGame, inputHistory, round }) =>
      dependencies.saveSystem?.saveGameWithId(currentGame.id, { game, gameHint, inputHistory, round })
    ),
    map(() => Action.empty())
  );
}

function loadGameOfType$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(SaveAction.loadGameOfType.match),
    mapPayload(),
    mergeMap((gameType) => dependencies.gameServer!.getGameOfType$(gameType, preferredLanguage(state$.value))),
    mergeMap((game) => {
      const savedGame: Optional<DGSave> = dependencies.saveSystem!.loadGameWithId(game.id);
      if (savedGame && (areGameEquals(game, savedGame.game) || savedGame.game.hasWon)) {
        return concat(of(GameAction.setGameFromSave(savedGame)), StateAction.setData(GameAction.statePrefix));
      }
      return concat(of(GameAction.setGame(game)), StateAction.setData(GameAction.statePrefix));
    })
  );
}

function loadGameWithId$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(SaveAction.loadGameWithId.match),
    mapPayload(),
    mergeMap((gameId) => dependencies.gameServer!.getGameWithId$(gameId, preferredLanguage(state$.value))),
    mergeMap((game) => {
      const savedGame: Optional<DGSave> = dependencies.saveSystem!.loadGameWithId(game.id);
      if (savedGame && (areGameEquals(game, savedGame.game) || savedGame.game.hasWon)) {
        return concat(of(GameAction.setGameFromSave(savedGame)), StateAction.setData(GameAction.statePrefix));
      }
      return concat(of(GameAction.setGame(game)), StateAction.setData(GameAction.statePrefix));
    })
  );
}

function clear$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(SaveAction.clear.match),
    tap(() => dependencies.saveSystem?.clear()),
    map(() => Action.empty())
  );
}

function areGameEquals(game: DGGame, comparison: GameState): boolean {
  switch (game.type) {
    case GameType.daily:
      const dailyData: DGGameDailyData = game.data as DGGameDailyData;
      return areWordsEquals(dailyData.image.words, comparison.words);
    case GameType.theme:
      const imageState: ThemeGameImageState = comparison.data?.imageState ?? ThemeGameImageState.image1;
      const themeData: DGGameThemeData = game.data as DGGameThemeData;
      return areWordsEquals(themeData.imageForState(imageState).words, comparison.words);
  }
}

function areWordsEquals(words: DGWord[], comparison: DGWord[]): boolean {
  if (words.length !== comparison.length) {
    return false;
  }
  for (let i: number = 0; i < words.length; i++) {
    if (words[i].text !== comparison[i].text) {
      return false;
    }
  }
  return true;
}

export default combineEpics(save$, loadGameOfType$, loadGameWithId$, clear$);
