import classNames from 'classnames';
import { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import { MenuItem } from './Models/MenuItem';

interface MenuProps {
  className?: string;
  items: MenuItem[];
  defaultValue?: 'undefined' | string;
  disabledItem?: string | ReactNode;
  onChange: (itemId: string) => void;
}

export const Menu: FunctionComponent<MenuProps> = ({ className, items, disabledItem, defaultValue, onChange }) => {
  function didSelectItem(event: ChangeEvent<HTMLSelectElement>): void {
    onChange(event.target.value);
  }

  return (
    <select
      className={classNames(
        'form-select block w-full px-3 py-1.5 text-base font-normal dark:text-white bg-white dark:bg-neutral-800 bg-clip-padding bg-no-repeat border border-solid border-gray-300 dark:border-neutral-700 rounded m-0',
        className
      )}
      defaultValue={defaultValue}
      onChange={didSelectItem}
    >
      {disabledItem ? (
        <option disabled value={'undefined'}>
          {disabledItem}
        </option>
      ) : undefined}
      {items.map((item) => (
        <option key={item.id} value={item.id}>
          {item.item}
        </option>
      ))}
    </select>
  );
};
