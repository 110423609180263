import classNames from 'classnames';
import { FunctionComponent, ReactElement } from 'react';
import { CustomButton } from '../CustomButton/CustomButton';

interface ButtonProps {
  className?: string;
  title?: string;
  icon?: ReactElement;
  padding?: number;
  onClick: () => void;
}

export const Button: FunctionComponent<ButtonProps> = ({ className, title, icon, padding = 16, onClick }) => {
  return (
    <CustomButton onClick={onClick}>
      <div className={classNames('h-stack items-center space-x-2', className)} style={{ padding: `${padding}px` }}>
        {icon ? icon : <></>}
        {title ? <div>{title}</div> : <></>}
      </div>
    </CustomButton>
  );
};
