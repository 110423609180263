import classNames from 'classnames';
import { ChangeEvent, FunctionComponent } from 'react';

export interface TextFieldProps {
  className?: string;
  reference?: any;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
}

export const TextField: FunctionComponent<TextFieldProps> = ({
  className,
  reference,
  value,
  placeholder,
  autoFocus,
  onChange,
  onSubmit
}) => {
  function keyDown(event: any): void {
    if (onSubmit && event.key === 'Enter') {
      onSubmit(event.target.value);
    }
  }

  function change(event: ChangeEvent<HTMLInputElement>): void {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <input
      className={classNames('appearance-none placeholder:text-secondary bg-transparent', className)}
      ref={reference}
      type="text"
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={change}
      onKeyDown={keyDown}
    />
  );
};
