/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { DGGame } from '../../../Models/App/Game/DGGame';
import { GameType } from '../../../Models/Enums/GameType';

const statePrefix: string = 'archives';

const fetchGames = createAction('archives/fetchGames');

const setGameType = createAction<GameType>('archives/setGameType');
const setGames = createAction<DGGame[]>('archives/setGames');

export default {
  statePrefix,
  fetchGames,
  setGameType,
  setGames
};
