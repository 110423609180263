import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DGWord } from '../../../../../Models/App/Word/DGWord';
import GameAction from '../../../../../Services/Redux/Game/GameAction';
import GameSelector from '../../../../../Services/Redux/Game/GameSelector';
import { State } from '../../../../../Services/Redux/State/State';
import ThemeGameAction from '../../../../../Services/Redux/ThemeGame/ThemeGameAction';
import ThemeGameSelector from '../../../../../Services/Redux/ThemeGame/ThemeGameSelector';
import { HintSimilarWordButton } from '../../HintSimilarWordButton/HintSimilarWordButton';
import { ImageGuess } from '../../ImageGuess/ImageGuess';
import { VerticalToHorizontalLayout } from '../../Layouts/VerticalToHorizontalLayout/VerticalToHorizontalLayout';
import { SubmitField } from '../../SubmitField/SubmitField';
import { ThemeImage } from './Components/ThemeImage/ThemeImage';

export const ThemeGame: FunctionComponent = () => {
  const dispatch = useDispatch();
  const guessPrefix: string = useSelector(ThemeGameSelector.guessPrefix);
  const words: DGWord[] = useSelector(GameSelector.words);
  const submitState: State = useSelector(GameSelector.submitState);
  const fieldValue: string = useSelector(GameSelector.fieldValue);

  useEffect(() => {
    dispatch(ThemeGameAction.setNextImageStateIfNeeded());
  }, [words]);

  function submitWord(word: string): void {
    dispatch(GameAction.submitWord(word));
  }

  function setFieldValue(value: string): void {
    dispatch(GameAction.setFieldValue(value));
  }

  return (
    <VerticalToHorizontalLayout>
      <div className="v-stack items-center justify-center">
        <ThemeImage />
      </div>

      <div className="v-stack items-center justify-center">
        <div className="w-full max-w-md v-stack items-center space-y-4">
          <ImageGuess prefix={guessPrefix ?? ''} words={words} />
          <HintSimilarWordButton />
          <SubmitField value={fieldValue} state={submitState.state} onChange={setFieldValue} onSubmit={submitWord} />
        </div>
      </div>
    </VerticalToHorizontalLayout>
  );
};
