import { DGWord } from './Word/DGWord';

export class DGImage {
  public constructor(
    public readonly url: string,
    public readonly guessPrefix: string,
    public readonly words: DGWord[]
  ) {}

  public get description(): string {
    return this.guessPrefix + ' ' + this.words.map((word) => word.text).join(' ');
  }
}
