import classNames from 'classnames';
import { Children, FunctionComponent, PropsWithChildren, ReactNode, useState } from 'react';
import { Optional } from '../../../../Utils/Types/Optional';
import { CarouselControl } from '../CarouselControl/CarouselControl';

interface CarouselProps {
  className?: string;
  isInfinite?: boolean;
  onComplete?: () => void;
}

export const Carousel: FunctionComponent<PropsWithChildren<CarouselProps>> = ({
  children,
  className,
  isInfinite = false,
  onComplete
}) => {
  const length: number = Children.count(children);
  const [index, setIndex] = useState(0);

  function elementForIndex(index: number): ReactNode {
    const node: Optional<ReactNode> = Children.toArray(children)[index];
    if (node) {
      return node;
    }
    return <></>;
  }

  function indexDidChange(index: number): void {
    setIndex(index);
  }

  return (
    <div className={classNames('v-stack items-center', className)}>
      {elementForIndex(index)}
      <div className="w-full">
        <CarouselControl
          index={index}
          length={length}
          isInfinite={isInfinite}
          onIndexChange={indexDidChange}
          onComplete={onComplete}
        />
      </div>
    </div>
  );
};
