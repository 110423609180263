/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { GameType } from '../../../Models/Enums/GameType';

const loadGameOfType = createAction<GameType>('save/loadGameOfType');
const loadGameWithId = createAction<string>('save/loadGameWithId');
const save = createAction('save/save');
const clear = createAction('save/clear');

export default {
  loadGameOfType,
  loadGameWithId,
  save,
  clear
};
