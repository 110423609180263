import { FunctionComponent, PropsWithChildren } from 'react';
import { Optional } from '../../../../Utils/Types/Optional';

interface SwitchProps extends PropsWithChildren {
  value: any;
}

export const Switch: FunctionComponent<SwitchProps> = ({ value, children }) => {
  function switchCase(): Optional<JSX.Element> {
    const caseComponent: Optional<JSX.Element> = (children as JSX.Element[]).find((c) => c.props.value === value);
    const defaultComponent: Optional<JSX.Element> = (children as JSX.Element[]).find(
      (c) => c.props.value === '*' || c.type.name === 'Default'
    );
    return caseComponent ? caseComponent : defaultComponent;
  }
  return <>{value ? switchCase() : undefined}</>;
};
