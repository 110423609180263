import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { concat, delay, filter, map, mergeMap, Observable, of } from 'rxjs';
import { DGGameThemeData } from '../../../Models/App/Game/DGGameThemeData';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameType } from '../../../Models/Enums/GameType';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import { Optional } from '../../../Utils/Types/Optional';
import { GameThemeData } from '../Game/Data/GameThemeData';
import GameAction from '../Game/GameAction';
import { GameState } from '../Game/GameState';
import { StoreState } from '../Global/StoreState';
import SaveAction from '../Save/SaveAction';
import StateAction from '../State/StateAction';
import { ThemeGameImageState } from './Models/ThemeGameImageState';
import ThemeGameAction from './ThemeGameAction';

function fetchDailyTheme$(action$: Observable<AnyAction>): Observable<AnyAction> {
  return action$.pipe(
    filter(ThemeGameAction.fetchThemeGame.match),
    mapPayload(),
    mergeMap((gameId) =>
      concat(
        StateAction.setLoading(GameAction.statePrefix),
        gameId ? of(SaveAction.loadGameWithId(gameId)) : of(SaveAction.loadGameOfType(GameType.theme))
      )
    )
  );
}

// Image State

function setNextImageStateIfNeeded$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>
): Observable<AnyAction> {
  return action$.pipe(
    filter(ThemeGameAction.setNextImageStateIfNeeded.match),
    map(() => state$.value.game.data as GameThemeData),
    filter(() => state$.value.game.words.length > 0),
    filter(() => (state$.value.game.data as GameThemeData).imageState !== ThemeGameImageState.theme),
    filter(() => areAllWordsGuessed(state$.value.game)),
    map((gameData) => nextImageStateForState(gameData.imageState)),
    delay(1000),
    mergeMap((nextState) =>
      concat([
        ThemeGameAction.setImageState(nextState),
        GameAction.setWords(wordsForImageState(state$.value, nextState))
      ])
    )
  );
}

function areAllWordsGuessed(state: GameState): boolean {
  return state.words.filter((word) => !word.isGuessed).length === 0;
}

function nextImageStateForState(imageState: ThemeGameImageState): ThemeGameImageState {
  switch (imageState) {
    case ThemeGameImageState.image1:
      return ThemeGameImageState.image2;
    case ThemeGameImageState.image2:
      return ThemeGameImageState.image3;
    case ThemeGameImageState.image3:
      return ThemeGameImageState.image4;
    case ThemeGameImageState.image4:
      return ThemeGameImageState.theme;
    default:
      return ThemeGameImageState.theme;
  }
}

function wordsForImageState(state: StoreState, imageState: ThemeGameImageState): DGWord[] {
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  if (!gameData) {
    return [];
  }
  switch (imageState) {
    case ThemeGameImageState.image1:
      return gameData.image1.words;
    case ThemeGameImageState.image2:
      return gameData.image2.words;
    case ThemeGameImageState.image3:
      return gameData.image3.words;
    case ThemeGameImageState.image4:
      return gameData.image4.words;
    default:
      return gameData.theme.split(' ').map((text) => DGWord.withText(text));
  }
}

export default combineEpics(fetchDailyTheme$, setNextImageStateIfNeeded$);
