import { DGRound } from '../../Models/App/DGRound';
import { DGRoundScore } from '../../Models/App/DGRoundScore';
import { GameType } from '../../Models/Enums/GameType';
import { RoundScoreCalculator } from '../RoundScoreCalculator/RoundScoreCalculator';
import { ShareTextBuilder } from './ShareTextBuilder';

export class DGShareTextBuilder implements ShareTextBuilder {
  public constructor(private readonly scoreCalculator: RoundScoreCalculator) {}

  public buildText(gameType: GameType, rounds: DGRound[], emojiLength: number): string {
    const data: { round: DGRound; score: DGRoundScore }[] = rounds.map((round) => ({
      round,
      score: this.scoreCalculator.calculateScoreForRound(round)
    }));
    const inputCount: number = data.map((data) => data.score.inputCount).reduce((a: number, b: number) => a + b, 0);
    return `${this.header(gameType, inputCount)}\n${this.stats(data, emojiLength)}\n`;
  }

  public url(): string {
    return 'https://dalle-guesser.com';
  }

  private header(gameType: GameType, inputCount: number): string {
    switch (gameType) {
      case GameType.daily:
        return `J'ai deviné l'image du jour en ${inputCount} coups sur Dall-E Guesser !`;
      case GameType.theme:
        return `J'ai deviné le thème du jour en ${inputCount} coups sur Dall-E Guesser !`;
    }
  }

  private stats(data: { round: DGRound; score: DGRoundScore }[], emojiLength: number): string {
    return data.map((data) => this.rowForScore(data.round, data.score, emojiLength)).join('\n');
  }

  private rowForScore(round: DGRound, score: DGRoundScore, emojiLength: number): string {
    const totalCount: number = score.guessedCount + score.suggestedCount + score.missedCount;
    const suggestedPercentage: number = score.suggestedCount / totalCount;
    const missedPercentage: number = score.missedCount / totalCount;

    const grayCount: number = Math.ceil(missedPercentage * (emojiLength - 1));
    const orangeCount: number = Math.ceil(suggestedPercentage * (emojiLength - 1));
    const grayString: string = '⬜️'.repeat(grayCount);
    const orangeString: string = '🟧'.repeat(orangeCount);
    const greenString: string = '🟩'.repeat(emojiLength - orangeCount - grayCount - 1);
    const hintString: string = round.usedHints.length === 0 ? '▪️' : '🃏';

    return `${greenString}${orangeString}${grayString}${hintString}`;
  }
}
