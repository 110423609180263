import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Divider } from '../Divider/Divider';

interface TutorialSectionProps {
  className?: string;
  description: string;
}

export const TutorialSection: FunctionComponent<PropsWithChildren<TutorialSectionProps>> = ({
  children,
  className,
  description
}) => {
  return (
    <div className={classNames('v-stack items-center space-y-4 w-full', className)}>
      <div className="text-center text-body">{description}</div>
      <div className="w-full">
        <Divider />
      </div>
      <div className="items-center justify-center grow v-stack">{children}</div>
    </div>
  );
};
