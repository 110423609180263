import { FunctionComponent } from 'react';

export const Footer: FunctionComponent = () => {
  return (
    <footer className="items-center pt-8 text-xs v-stack text-secondary pb-safe">
      <div className="items-center space-x-2 h-stack">
        <a className="no-underline" href="/about">
          À propos
        </a>
        <div>•</div>
        <a className="no-underline" href="https://ko-fi.com/bpisano">
          Soutenir
        </a>
        <div>•</div>
        <a className="no-underline" href="mailto:dalle.guesser@gmail.com?subject=Contact">
          Contact
        </a>
      </div>
    </footer>
  );
};
