/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { DGSave } from '../../../Models/App/DGSave';
import { DGGame } from '../../../Models/App/Game/DGGame';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { State } from '../State/State';

const statePrefix: string = 'game';

const setGame = createAction<DGGame>('game/setGame');
const setGameFromSave = createAction<DGSave>('game/setGameFromSave');

const setSubmitState = createAction<State>('game/setSubmitState');
const submitWord = createAction<string>('game/submitWord');
const setWords = createAction<DGWord[]>('game/setWords');
const updateWord = createAction<DGWord>('game/updateWord');

const setFieldValue = createAction<string>('game/setFieldValue');
const clearField = createAction('game/clearField');

export default {
  statePrefix,
  setGame,
  setGameFromSave,
  setSubmitState,
  submitWord,
  setWords,
  updateWord,
  setFieldValue,
  clearField
};
