import classNames from 'classnames';
import { FunctionComponent, ReactElement } from 'react';
import { GameType } from '../../../../../Models/Enums/GameType';

interface GameButtonIconProps {
  gameType: GameType;
}

export const GameButtonIcon: FunctionComponent<GameButtonIconProps> = ({ gameType }) => {
  const strokeWidth: number = 2;

  function gradient(): string {
    switch (gameType) {
      case GameType.daily:
        return 'bg-gradient-to-b from-cyan-500 to-blue-500';
      case GameType.theme:
        return 'bg-gradient-to-b from-pink-500 to-purple-500';
    }
  }

  function icon(): ReactElement {
    switch (gameType) {
      case GameType.daily:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={strokeWidth}
            stroke="white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        );
      case GameType.theme:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={strokeWidth}
            stroke="white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
            />
          </svg>
        );
    }
  }

  return (
    <div className={classNames('w-14 h-14 v-stack items-center justify-center rounded-xl shadow-md', gradient())}>
      <div className="w-8 h-8 drop-shadow-md">{icon()}</div>
    </div>
  );
};
