/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import { DGGame } from '../../../Models/App/Game/DGGame';
import { DGGameDailyData } from '../../../Models/App/Game/DGGameDailyData';
import { DGGameThemeData } from '../../../Models/App/Game/DGGameThemeData';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameType } from '../../../Models/Enums/GameType';
import StateReducer from '../State/StateReducer';
import { ThemeGameImageState } from '../ThemeGame/Models/ThemeGameImageState';
import ThemeGameReducer from '../ThemeGame/ThemeGameReducer';
import { GameData } from './Data/GameData';
import { GameThemeData } from './Data/GameThemeData';
import GameAction from './GameAction';
import { GameState } from './GameState';

const initialState: GameState = {
  state: 'loading',
  submitState: { state: 'waitingForInput' },
  fieldValue: '',
  words: [],
  inputHistory: [],
  inputCount: 0,
  hasWon: false
};
export const gameReducer = createReducer<GameState>(initialState, (builder) => {
  StateReducer.addBuilderStateCase(builder, GameAction.statePrefix);
  ThemeGameReducer.addBuilderThemeGameAction(builder);

  builder.addCase(GameAction.setGame, (state, action) => ({
    ...state,
    current: action.payload,
    data: dataForGame(action.payload),
    words: wordsFromGame(action.payload),
    inputHistory: [],
    inputCount: 0,
    hasWon: false
  }));
  builder.addCase(GameAction.setGameFromSave, (state, action) => ({
    ...state,
    ...action.payload.game,
    submitState: { state: 'waitingForInput' }
  }));

  builder.addCase(GameAction.setSubmitState, (state, action) => ({
    ...state,
    submitState: action.payload
  }));
  builder.addCase(GameAction.setWords, (state, action) => ({
    ...state,
    words: action.payload,
    hasWon: state.current ? hasWon(state.current, state.data, action.payload) : false
  }));
  builder.addCase(GameAction.updateWord, (state, action) => ({
    ...state,
    words: state.words.map((word) => (word.id === action.payload.id ? action.payload : word)),
    hasWon: state.current ? hasWon(state.current, state.data, state.words) : false
  }));

  builder.addCase(GameAction.setFieldValue, (state, action) => ({
    ...state,
    fieldValue: action.payload
  }));
  builder.addCase(GameAction.clearField, (state) => ({
    ...state,
    fieldValue: ''
  }));
});

export function wordsFromGame(game: DGGame): DGWord[] {
  switch (game.type) {
    case GameType.daily:
      return (game.data as DGGameDailyData).image.words;
    case GameType.theme:
      return (game.data as DGGameThemeData).image1.words;
  }
}

function hasWon(game: DGGame, data: GameData, words: DGWord[]): boolean {
  switch (game.type) {
    case GameType.daily:
      return words.filter((word) => !word.isGuessed).length === 0;
    case GameType.theme:
      return (
        (data as GameThemeData).imageState === ThemeGameImageState.theme &&
        words.filter((word) => !word.isGuessed).length === 0
      );
  }
}

function dataForGame(game: DGGame): GameData {
  switch (game.type) {
    case GameType.theme:
      return { imageState: ThemeGameImageState.image1 };
    default:
      return undefined;
  }
}
