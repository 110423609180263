import Axios from 'axios-observable';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';
import { ApiWord } from '../../../Models/Api/Word/ApiWord';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { Language } from '../../../Models/Enums/Language';
import { WordServer } from './WordServer';

export class ApiWordServer implements WordServer {
  public submit$(input: string, words: DGWord[], language: Language): Observable<DGWord[]> {
    return Axios.post(`${process.env.REACT_APP_API_URL}/word/submit`, {
      input,
      language,
      words: words.map((word) => plainToInstance(DGWord, word)).map((word) => word.toApiModel())
    }).pipe(
      map((response) => response.data.map((data: any) => ApiWord.fromJson(data))),
      map((apiWords: ApiWord[]) => apiWords.map((word) => word.toAppModel()))
    );
  }
}
