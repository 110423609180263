import classNames from 'classnames';
import { FunctionComponent } from 'react';

interface GameImageProps {
  className?: string;
  src: string;
}

export const GameImage: FunctionComponent<GameImageProps> = ({ className, src }) => {
  return <img className={classNames('rounded-xl shadow-xl', className)} src={src} alt="" />;
};
