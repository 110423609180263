import { FunctionComponent } from 'react';
import { WordContainer } from './Components/WordContainer';
import { WordPrefix } from './Components/WordPrefix';
import { WordSuffix } from './Components/WordSuffix';

interface Props {
  text: string;
  suggestion: string;
  similarity: number;
  isSelectingHintWord: boolean;
  onClick: () => void;
}

export const WordSuggested: FunctionComponent<Props> = ({
  text,
  suggestion,
  similarity,
  isSelectingHintWord,
  onClick
}) => {
  function textOpacity(): number {
    return Math.max(0, Math.min(1, similarity));
  }

  return (
    <div className="h-stack">
      <WordPrefix text={text} />
      <WordContainer isSelectingHintWord={isSelectingHintWord} onClick={onClick}>
        <div className="inline-block px-1" style={{ opacity: textOpacity() }}>
          {suggestion}
        </div>
      </WordContainer>
      <WordSuffix text={text} />
    </div>
  );
};
