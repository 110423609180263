import { FunctionComponent } from 'react';
import { SidebarLayout } from '../../Components/Layouts/SidebarLayout/SidebarLayout';
import { Sidebar } from './Components/Sidebar/Sidebar';
import { Tips } from './Components/Tips/Tips';

export const HomePage: FunctionComponent = () => {
  return (
    <SidebarLayout>
      <Sidebar />
      <Tips />
    </SidebarLayout>
  );
};
