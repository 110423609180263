import { DGSave } from '../../Models/App/DGSave';
import { GameType } from '../../Models/Enums/GameType';
import { SAVE_SYSTEM_VERSION } from '../../Utils/Constant';
import { Nullable } from '../../Utils/Types/Nullable';
import { Optional } from '../../Utils/Types/Optional';
import { gameStateFromSave } from '../Redux/Game/GameState';
import { SaveSystem } from './SaveSystem';

export class LocalStorageSave implements SaveSystem {
  private saveVersionKey: string = 'save_version';
  private gamePrefix: string = 'game_';

  public constructor() {
    this.checkSaveVersion();
  }

  public get hasCompletedTutorial(): boolean {
    const hasCompletedTutorial: Nullable<string> = localStorage.getItem('hasCompletedTutorial');
    return hasCompletedTutorial === 'true';
  }

  public loadGameWithId(id: string): Optional<DGSave> {
    const save: Nullable<string> = localStorage.getItem(`${this.gamePrefix}${id}`);
    if (save) {
      return this.parseSave(save);
    }
    return undefined;
  }

  public saveGameWithId(id: string, save: DGSave): void {
    localStorage.setItem(`${this.gamePrefix}${id}`, JSON.stringify(save));
  }

  public clear(): void {
    localStorage.clear();
  }

  public hasWonGameWithId(id: string): boolean {
    const save: Optional<DGSave> = this.loadGameWithId(id);
    return save ? save.game.hasWon : false;
  }

  public wonGameCountOfType(gameType: GameType): number {
    let count: number = 0;
    for (const key in localStorage) {
      if (!key.startsWith(this.gamePrefix)) {
        continue;
      }
      const gameId: string = key.substring(this.gamePrefix.length);
      const save: Optional<DGSave> = this.loadGameWithId(gameId);
      if (save && save.game.current?.type === gameType && save.game.hasWon) {
        count++;
      }
    }
    return count;
  }

  public setHasCompletedTutorial(hasCompletedTutorial: boolean): void {
    localStorage.setItem('hasCompletedTutorial', `${hasCompletedTutorial}`);
  }

  private checkSaveVersion(): void {
    const savedVersion: Nullable<string> = localStorage.getItem(this.saveVersionKey);
    if (savedVersion !== SAVE_SYSTEM_VERSION) {
      localStorage.clear();
      localStorage.setItem(this.saveVersionKey, SAVE_SYSTEM_VERSION);
    }
  }

  private parseSave(save: string): DGSave {
    const jsonSave: any = JSON.parse(save);
    return {
      game: gameStateFromSave(jsonSave.game),
      gameHint: jsonSave.gameHint,
      inputHistory: jsonSave.inputHistory,
      round: jsonSave.round
    };
  }
}
