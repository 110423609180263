import Axios from 'axios-observable';
import { map, Observable } from 'rxjs';
import { ApiGame } from '../../../Models/Api/Game/ApiGame';
import { DGGame } from '../../../Models/App/Game/DGGame';
import { GameType } from '../../../Models/Enums/GameType';
import { Language } from '../../../Models/Enums/Language';
import { GameServer } from './GameServer';

export class ApiGameServer implements GameServer {
  public getGameWithId$(id: string, language: Language): Observable<DGGame> {
    return Axios.get(`${process.env.REACT_APP_API_URL}/game/${id}?language=${language}`).pipe(
      map((response) => ApiGame.fromJson(response.data)),
      map((apiGame) => apiGame.toAppModel())
    );
  }

  public getGameOfType$(gameType: GameType, language: Language): Observable<DGGame> {
    switch (gameType) {
      case GameType.daily:
        return this.getDailyGame$(language);
      case GameType.theme:
        return this.getThemeGame$(language);
    }
  }

  public getDailyGame$(language: Language): Observable<DGGame> {
    return Axios.get(`${process.env.REACT_APP_API_URL}/game/daily?language=${language}`).pipe(
      map((response) => ApiGame.fromJson(response.data)),
      map((apiGame) => apiGame.toAppModel())
    );
  }

  public getThemeGame$(language: Language): Observable<DGGame> {
    return Axios.get(`${process.env.REACT_APP_API_URL}/game/theme?language=${language}`).pipe(
      map((response) => ApiGame.fromJson(response.data)),
      map((apiGame) => apiGame.toAppModel())
    );
  }

  public getAllDailyGames$(language: Language): Observable<DGGame[]> {
    return Axios.get(`${process.env.REACT_APP_API_URL}/games/daily?language=${language}`).pipe(
      map((response) => response.data.map((data: any) => ApiGame.fromJson(data))),
      map((apiGames) => apiGames.map((apiGame: ApiGame) => apiGame.toAppModel()))
    );
  }

  public getAllThemeGames$(language: Language): Observable<DGGame[]> {
    return Axios.get(`${process.env.REACT_APP_API_URL}/games/theme?language=${language}`).pipe(
      map((response) => response.data.map((data: any) => ApiGame.fromJson(data))),
      map((apiGames) => apiGames.map((apiGame: ApiGame) => apiGame.toAppModel()))
    );
  }
}
