/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import { DGRound } from '../../../Models/App/DGRound';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameHint } from '../../../Models/Enums/GameHint';
import { WordState } from '../../../Models/Enums/WordState';
import GameAction from '../Game/GameAction';
import ThemeGameAction from '../ThemeGame/ThemeGameAction';
import RoundAction from './RoundAction';
import { RoundState } from './RoundState';

const initialState: RoundState = {
  rounds: [emptyRound()]
};
export const roundReducer = createReducer<RoundState>(initialState, (builder) => {
  builder.addCase(RoundAction.addInput, (state, action) => ({
    ...state,
    rounds: roundsFromInput(action.payload.input, action.payload.words, state)
  }));
  builder.addCase(ThemeGameAction.setImageState, (state) => ({
    ...state,
    rounds: [...state.rounds, emptyRound()]
  }));
  builder.addCase(RoundAction.addUsedHint, (state, action) => ({
    ...state,
    rounds: roundsFromHint(action.payload, state)
  }));
  builder.addCase(GameAction.setGame, () => initialState);
  builder.addCase(GameAction.setGameFromSave, (state, action) => ({
    ...state,
    ...action.payload.round
  }));
});

function emptyRound(): DGRound {
  return {
    inputs: [],
    usedHints: []
  };
}

function roundsFromInput(input: string, words: DGWord[], state: RoundState): DGRound[] {
  const lastRound: DGRound = state.rounds[state.rounds.length - 1];
  if (roundContainsInput(lastRound, input)) {
    return state.rounds;
  }
  return state.rounds.map((round, index) => {
    if (index === state.rounds.length - 1) {
      return addInputToRound(round, input, words);
    }
    return round;
  });
}

function roundContainsInput(round: DGRound, input: string): boolean {
  return round.inputs.some((wordInput) => wordInput.input.toLowerCase() === input.toLowerCase());
}

function addInputToRound(round: DGRound, input: string, words: DGWord[]): DGRound {
  return {
    ...round,
    inputs: [
      ...round.inputs,
      {
        input,
        guessedCount: words.filter((word) => word.state === WordState.recentlyGuessed).length,
        suggestedCount: words.filter((word) => word.state === WordState.recentlySuggested).length
      }
    ]
  };
}

function roundsFromHint(hint: GameHint, state: RoundState): DGRound[] {
  return state.rounds.map((round, index) => {
    if (index === state.rounds.length - 1) {
      return addHintToRound(round, hint);
    }
    return round;
  });
}

function addHintToRound(round: DGRound, hint: GameHint): DGRound {
  return {
    ...round,
    usedHints: [...round.usedHints, hint]
  };
}
