/* eslint-disable @typescript-eslint/typedef */
import { AnyAction, createAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { Observable, of } from 'rxjs';

const loading = (prefix: string): PayloadActionCreator => createAction(prefix + '/loading');
const data = (prefix: string): PayloadActionCreator => createAction(prefix + '/data');
const error = (prefix: string): PayloadActionCreator<{ code: string }> =>
  createAction<{ code: string }>(prefix + '/error');
const success = (prefix: string): PayloadActionCreator => createAction(prefix + '/success');
const custom = (prefix: string): PayloadActionCreator<string> => createAction(prefix + '/custom');

function setLoading(prefix: string): Observable<AnyAction> {
  return of(loading(prefix)());
}

function setData(prefix: string): Observable<AnyAction> {
  return of(data(prefix)());
}

function setError(prefix: string, code: string): Observable<AnyAction> {
  return of(error(prefix)({ code }));
}

function setSuccess(prefix: string): Observable<AnyAction> {
  return of(success(prefix)());
}

function setCustom(prefix: string, state: string): Observable<AnyAction> {
  return of(custom(prefix)(state));
}

export default {
  loading,
  data,
  error,
  success,
  custom,
  setLoading,
  setData,
  setError,
  setSuccess,
  setCustom
};
