import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

interface CustomButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const CustomButton: FunctionComponent<PropsWithChildren<CustomButtonProps>> = ({
  children,
  className,
  disabled = false,
  onClick
}) => {
  return (
    <button
      className={classNames(
        'rounded-lg transition ease-in-out',
        !disabled && 'hover:bg-neutral-200 dark:hover:bg-neutral-800',
        disabled && 'opacity-30',
        'active:opacity-20',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
