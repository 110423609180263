import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { State } from './State';
import ComponentStateActions from './StateAction';

function addBuilderStateCase<T extends State>(builder: ActionReducerMapBuilder<T>, prefix: string): void {
  builder.addCase(ComponentStateActions.loading(prefix), (currentState) => ({
    ...currentState,
    state: 'loading',
    error: undefined
  }));
  builder.addCase(ComponentStateActions.data(prefix), (currentState) => ({
    ...currentState,
    state: 'data',
    error: undefined
  }));
  builder.addCase(ComponentStateActions.error(prefix), (currentState, action) => ({
    ...currentState,
    state: 'error',
    error: action.payload
  }));
  builder.addCase(ComponentStateActions.success(prefix), (currentState) => ({
    ...currentState,
    state: 'success',
    error: undefined
  }));
  builder.addCase(ComponentStateActions.custom(prefix), (currentState, action) => ({
    ...currentState,
    state: action.payload,
    error: undefined
  }));
}

export default {
  addBuilderStateCase
};
