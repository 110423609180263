import { FunctionComponent } from 'react';
import { isPunctuation } from '../../../../../Utils/String';

interface Props {
  text: string;
}

export const WordSuffix: FunctionComponent<Props> = ({ text }) => {
  const characters: string[] = text.split('');

  return <>{isPunctuation(characters[characters.length - 1]) ? characters[characters.length - 1] : undefined}</>;
};
