import { ToAppModelConvertible } from 'model-conversion';
import { DGGameThemeData } from '../../App/Game/DGGameThemeData';
import { ApiImage } from '../ApiImage';

export class ApiGameThemeData implements ToAppModelConvertible<DGGameThemeData> {
  public date: Date;
  public image1: ApiImage;
  public image2: ApiImage;
  public image3: ApiImage;
  public image4: ApiImage;
  public theme: string;
  public referenceUrl?: string;

  private constructor(
    date: Date,
    image1: ApiImage,
    image2: ApiImage,
    image3: ApiImage,
    image4: ApiImage,
    theme: string,
    referenceUrl?: string
  ) {
    this.date = date;
    this.image1 = image1;
    this.image2 = image2;
    this.image3 = image3;
    this.image4 = image4;
    this.theme = theme;
    this.referenceUrl = referenceUrl;
  }

  public static fromJson(json: any): ApiGameThemeData {
    const image1: ApiImage = ApiImage.fromJson(json.image1);
    const image2: ApiImage = ApiImage.fromJson(json.image2);
    const image3: ApiImage = ApiImage.fromJson(json.image3);
    const image4: ApiImage = ApiImage.fromJson(json.image4);
    return new ApiGameThemeData(new Date(json.date), image1, image2, image3, image4, json.theme, json.reference_url);
  }

  public toAppModel(): DGGameThemeData {
    return new DGGameThemeData(
      this.date,
      this.image1.toAppModel(),
      this.image2.toAppModel(),
      this.image3.toAppModel(),
      this.image4.toAppModel(),
      this.theme,
      this.referenceUrl
    );
  }
}
