import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { Logo } from '../Logo/Logo';
import { HomeButton } from './Components/HomeButton/HomeButton';

interface NavigationBarProps {
  sticky?: boolean;
}

export const NavigationBar: FunctionComponent<NavigationBarProps> = ({ sticky = false }) => {
  return (
    <nav className={classNames('grid w-full grid-cols-3 py-2 overflow-visible', sticky ? 'sticky top-0' : undefined)}>
      <div className="items-start justify-center v-stack">
        <HomeButton />
      </div>

      <div className="items-center overflow-visible v-stack">
        <div className="w-[120px] overflow-visible">
          <Logo />
        </div>
      </div>
    </nav>
  );
};
