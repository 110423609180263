/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import { GameType } from '../../../Models/Enums/GameType';
import StateReducer from '../State/StateReducer';
import ArchivesAction from './ArchivesAction';
import { ArchivesState } from './ArchivesState';

const initialState: ArchivesState = {
  state: 'loading',
  gameType: GameType.daily,
  games: []
};
export const archivesReducer = createReducer<ArchivesState>(initialState, (builder) => {
  StateReducer.addBuilderStateCase(builder, ArchivesAction.statePrefix);
  builder.addCase(ArchivesAction.setGameType, (state, action) => ({
    ...state,
    gameType: action.payload
  }));
  builder.addCase(ArchivesAction.setGames, (state, action) => ({
    ...state,
    games: action.payload
  }));
});
