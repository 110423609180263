/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { DGGameDailyData } from '../../../Models/App/Game/DGGameDailyData';
import { Optional } from '../../../Utils/Types/Optional';
import { storeSelector } from '../Global/StoreSelector';

const guessPrefix = createSelector(storeSelector, (state) => {
  const data: Optional<DGGameDailyData> = state.game.current?.data as DGGameDailyData;
  return data?.image.guessPrefix ?? '';
});
const imageUrl = createSelector(storeSelector, (state) => {
  const data: Optional<DGGameDailyData> = state.game.current?.data as DGGameDailyData;
  return data?.image.url ?? '';
});

export default {
  guessPrefix,
  imageUrl
};
