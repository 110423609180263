/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { EventArgs } from 'react-ga';

const trackNavigation = createAction('analytics/trackNavigation');
const trackEvent = createAction<EventArgs>('analytics/trackEvent');

export default {
  trackNavigation,
  trackEvent
};
