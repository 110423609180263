import anime from 'animejs';
import { FunctionComponent, useEffect } from 'react';

interface WordRecentlyGuessedProps {
  text: string;
}

export const WordRecentlyGuessed: FunctionComponent<WordRecentlyGuessedProps> = ({ text }) => {
  const characters: string[] = text.split('');

  useEffect(() => {
    anime({
      targets: '.recently-guessed-letter',
      scale: [0, 1],
      delay: anime.stagger(20)
    });
  }, [text]);

  return (
    <div className="text-green-500 h-stack">
      {characters.map((character, index) => (
        <div className="recently-guessed-letter" key={index}>
          {character}
        </div>
      ))}
    </div>
  );
};
