import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { DGRound } from '../../../../Models/App/DGRound';
import { DGRoundScore } from '../../../../Models/App/DGRoundScore';
import { allGameHints, GameHint } from '../../../../Models/Enums/GameHint';
import { DGRoundScoreCalculator } from '../../../../Services/RoundScoreCalculator/DGRoundScoreCalculator';

interface RoundGaugeProps {
  className?: string;
  round: DGRound;
}

export const RoundGauge: FunctionComponent<RoundGaugeProps> = ({ className, round }) => {
  const scoreCalculator: DGRoundScoreCalculator = new DGRoundScoreCalculator();
  const score: DGRoundScore = scoreCalculator.calculateScoreForRound(round);

  function isHintUsed(hint: GameHint): boolean {
    return round.usedHints.includes(hint);
  }

  return (
    <div className={classNames('h-stack items-center space-x-2', className)}>
      <div className={'h-stack grow bg-white rounded-md overflow-hidden'}>
        <div
          className="h-full text-green-300 text-sm text-left font-medium bg-green-500"
          style={{ width: `${score.guessedPercentage * 100}%` }}
        >
          <div className="pl-2">{score.guessedCount}</div>
        </div>
        <div
          className="h-full text-orange-200 text-sm text-left font-medium bg-orange-400"
          style={{ width: `${score.suggestedPercentage * 100}%` }}
        >
          <div className="pl-2">{score.suggestedCount}</div>
        </div>
        <div
          className="h-full text-gray-200 dark:text-neutral-200 text-sm text-left font-medium bg-gray-300 dark:bg-neutral-400"
          style={{ width: `${score.missedPercentage * 100}%` }}
        >
          <div className="pl-2">{score.missedCount}</div>
        </div>
      </div>

      <div className="w-6 text-gray-800 dark:text-neutral-300 text-center text-sm font-medium">{score.inputCount}</div>

      <div className="w-4 v-stack items-center">
        {allGameHints.map((hint) =>
          isHintUsed(hint) ? (
            <div key={hint} className="h-2 h-stack items-center text-xl rotate-6">
              🃏
            </div>
          ) : (
            <div key={hint} className="w-2 h-2 bg-gray-300 dark:bg-neutral-700 rounded-full"></div>
          )
        )}
      </div>
    </div>
  );
};
