import { FunctionComponent } from 'react';
import { DGWord } from '../../../../Models/App/Word/DGWord';
import { Word } from '../Word/Word';

interface ImageGuessProps {
  prefix: string;
  words: DGWord[];
}

export const ImageGuess: FunctionComponent<ImageGuessProps> = ({ prefix, words }) => {
  function divForPrefix(prefix: string): JSX.Element[] {
    return prefix.split(' ').map((word, index) => (
      <div key={index} className="my-0.5">
        {word}
      </div>
    ));
  }

  return (
    <div className="flex flex-wrap justify-center space-x-[4px] lg:text-xl">
      {divForPrefix(prefix)}
      {words.map((word) => (
        <div key={word.id} className="my-0.5">
          <Word word={word} />
        </div>
      ))}
    </div>
  );
};
