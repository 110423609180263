import { AnyAction } from '@reduxjs/toolkit';
import { plainToInstance } from 'class-transformer';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, concat, filter, map, mergeMap, of } from 'rxjs';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameHint } from '../../../Models/Enums/GameHint';
import { WordState } from '../../../Models/Enums/WordState';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import AnalyticsAction from '../Analytics/AnalyticsAction';
import GameAction from '../Game/GameAction';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import RoundAction from '../Round/RoundAction';
import GameHintAction from './GameHintAction';

function applyHintOnWord$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>,
  _dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(GameHintAction.applyHintOnWord.match),
    mapPayload(),
    map((word) => ({ word, hint: state$.value.gameHint.selectedHint ?? GameHint.revealFirstLetter })),
    mergeMap(({ word, hint }) =>
      concat(
        of(GameHintAction.deselectGameHint()),
        applyHint$(hint, word),
        of(RoundAction.addUsedHint(hint)),
        of(AnalyticsAction.trackEvent({ category: 'game', action: 'hint' }))
      )
    )
  );
}

function applyHint$(hint: GameHint, word: DGWord): Observable<AnyAction> {
  switch (hint) {
    case GameHint.revealFirstLetter:
      return revealFirstLetter$(word);
  }
}

function revealFirstLetter$(word: DGWord): Observable<AnyAction> {
  return of(word).pipe(
    map((word) => {
      const wordCopy: DGWord = plainToInstance(DGWord, word);
      wordCopy.state = WordState.revealFirstLetter;
      wordCopy.revealedLetters += 1;
      if (wordCopy.revealedLetters === wordCopy.text.length) {
        wordCopy.state = WordState.recentlyGuessed;
      }
      return wordCopy;
    }),
    map((word) => GameAction.updateWord(word))
  );
}

export default combineEpics(applyHintOnWord$);
