import { DGRound } from '../../Models/App/DGRound';
import { DGRoundScore } from '../../Models/App/DGRoundScore';
import { RoundScoreCalculator } from './RoundScoreCalculator';

export class DGRoundScoreCalculator implements RoundScoreCalculator {
  public calculateScoreForRound(round: DGRound): DGRoundScore {
    const guessedCount: number = round.inputs
      .map((input) => (input.guessedCount > 0 ? 1 : 0))
      .reduce((a: number, b: number) => a + b, 0);
    const suggestedCount: number = round.inputs
      .map((input) => (input.suggestedCount > 0 ? 1 : 0))
      .reduce((a: number, b: number) => a + b, 0);
    const missedCount: number = round.inputs
      .map((input) => (input.guessedCount + input.suggestedCount === 0 ? 1 : 0))
      .reduce((a: number, b: number) => a + b, 0);
    const totalCount: number = guessedCount + suggestedCount + missedCount;
    const inputCount: number = round.inputs.length;

    const guessedPercentage: number = guessedCount / totalCount;
    const suggestedPercentage: number = suggestedCount / totalCount;
    const missedPercentage: number = missedCount / totalCount;

    return {
      inputCount,
      guessedCount,
      suggestedCount,
      missedCount,
      guessedPercentage,
      suggestedPercentage,
      missedPercentage
    };
  }
}
