/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { DGGameThemeData } from '../../../Models/App/Game/DGGameThemeData';
import { Optional } from '../../../Utils/Types/Optional';
import { GameThemeData } from '../Game/Data/GameThemeData';
import { storeSelector } from '../Global/StoreSelector';
import { ThemeGameImageState } from './Models/ThemeGameImageState';

const imageState = createSelector(storeSelector, (state) => {
  const data: Optional<GameThemeData> = state.game.data as GameThemeData;
  return data?.imageState ?? ThemeGameImageState.image1;
});
const currentImageUrl = createSelector(storeSelector, (state) => {
  const data: Optional<GameThemeData> = state.game.data as GameThemeData;
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  if (!data || !gameData) {
    return undefined;
  }
  switch (data?.imageState) {
    case ThemeGameImageState.image1:
      return gameData.image1.url;
    case ThemeGameImageState.image2:
      return gameData.image2.url;
    case ThemeGameImageState.image3:
      return gameData.image3.url;
    case ThemeGameImageState.image4:
      return gameData.image4.url;
    case ThemeGameImageState.theme:
      return undefined;
  }
});
const guessedImages = createSelector(storeSelector, (state) => {
  const data: Optional<GameThemeData> = state.game.data as GameThemeData;
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  if (!data || !gameData) {
    return [];
  }
  switch (data.imageState) {
    case ThemeGameImageState.image1:
      return [];
    case ThemeGameImageState.image2:
      return [gameData.image1];
    case ThemeGameImageState.image3:
      return [gameData.image1, gameData.image2];
    case ThemeGameImageState.image4:
      return [gameData.image1, gameData.image2, gameData.image3];
    case ThemeGameImageState.theme:
      return [gameData.image1, gameData.image2, gameData.image3, gameData.image4];
  }
});
const guessPrefix = createSelector(storeSelector, (state) => {
  const data: Optional<GameThemeData> = state.game.data as GameThemeData;
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  if (!data || !gameData) {
    return '';
  }
  switch (data.imageState) {
    case ThemeGameImageState.image1:
      return gameData.image1.guessPrefix;
    case ThemeGameImageState.image2:
      return gameData.image2.guessPrefix;
    case ThemeGameImageState.image3:
      return gameData.image3.guessPrefix;
    case ThemeGameImageState.image4:
      return gameData.image4.guessPrefix;
    case ThemeGameImageState.theme:
      return '';
  }
});

export default {
  imageState,
  currentImageUrl,
  guessedImages,
  guessPrefix
};
