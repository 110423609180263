import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DGGame } from '../../../../Models/App/Game/DGGame';
import { allGameTypes, GameType } from '../../../../Models/Enums/GameType';
import ArchivesAction from '../../../../Services/Redux/Archives/ArchivesAction';
import ArchivesSelector from '../../../../Services/Redux/Archives/ArchivesSelector';
import { ArchiveGameButton } from '../../Components/Buttons/ArchiveGameButton/ArchiveGameButton';
import { Divider } from '../../Components/Divider/Divider';
import { PageLayout } from '../../Components/Layouts/PageLayout/PageLayout';
import { Menu } from '../../Components/Menu/Menu';
import { MenuItem } from '../../Components/Menu/Models/MenuItem';

export const ArchivePage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const gameType: GameType = useSelector(ArchivesSelector.gameType);
  const games: DGGame[] = useSelector(ArchivesSelector.games);

  useEffect(() => {
    dispatch(ArchivesAction.fetchGames());
  }, []);

  useEffect(() => {
    dispatch(ArchivesAction.setGameType(gameType));
    dispatch(ArchivesAction.fetchGames());
  }, [gameType]);

  function itemTextForGameType(gameType: GameType): string {
    switch (gameType) {
      case GameType.daily:
        return 'Images du jour';
      case GameType.theme:
        return 'Thèmes du jour';
    }
  }

  function menuItemForGameType(gameType: GameType): MenuItem {
    return {
      id: gameType,
      item: itemTextForGameType(gameType)
    };
  }

  function didSelectGameType(gameType: string): void {
    dispatch(ArchivesAction.setGameType(gameType as GameType));
  }

  return (
    <PageLayout stickyNavigationBar={true}>
      <main className="w-full max-w-lg space-y-4 v-stack">
        <Menu items={allGameTypes.map(menuItemForGameType)} onChange={didSelectGameType} />

        <div className="v-stack">
          {games.map((game) => (
            <div key={game.id} className="mb-1 space-y-1 v-stack">
              <ArchiveGameButton game={game} />
              <Divider />
            </div>
          ))}
        </div>
      </main>
    </PageLayout>
  );
};
