import { FunctionComponent } from 'react';
import { isPunctuation } from '../../../../../Utils/String';

interface Props {
  text: string;
}

export const WordHiddenText: FunctionComponent<Props> = ({ text }) => {
  const characters: string[] = text.split('');

  return (
    <div className="select-none">
      {characters.map((character, index) => {
        const isFirstCharacter: boolean = index === 0;
        const isLastCharacter: boolean = index === characters.length - 1;
        if ((isFirstCharacter || isLastCharacter) && isPunctuation(character)) {
          return '';
        }
        if (isPunctuation(character)) {
          return character;
        }
        if (text.length === 1) {
          return '\u00A0\u00A0\u00A0\u00A0\u00A0';
        }
        return '\u00A0\u00A0\u00A0';
      })}
    </div>
  );
};
