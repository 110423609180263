/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { storeSelector } from '../Global/StoreSelector';

const rounds = createSelector(storeSelector, (state) => state.round.rounds);
const inputCount = createSelector(storeSelector, (state) =>
  state.round.rounds.map((round) => round.inputs.length).reduce((a, b) => a + b, 0)
);

export default {
  rounds,
  inputCount
};
