import { ToAppModelConvertible } from 'model-conversion';
import { DGImage } from '../App/DGImage';
import { ApiWord } from './Word/ApiWord';

export class ApiImage implements ToAppModelConvertible<DGImage> {
  public readonly url: string;
  public readonly guessPrefix: string;
  public readonly words: ApiWord[];

  private constructor(url: string, guessPrefix: string, words: ApiWord[]) {
    this.url = url;
    this.guessPrefix = guessPrefix;
    this.words = words;
  }

  public static fromJson(json: any): ApiImage {
    return new ApiImage(
      json.url,
      json.guessPrefix,
      json.words.map((word: any) => ApiWord.fromJson(word))
    );
  }

  public toAppModel(): DGImage {
    return new DGImage(
      this.url,
      this.guessPrefix,
      this.words.map((word: ApiWord) => word.toAppModel())
    );
  }
}
