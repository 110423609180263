import { format } from 'date-fns';
import { FunctionComponent } from 'react';
import { DGGame } from '../../../../../Models/App/Game/DGGame';
import { GameType } from '../../../../../Models/Enums/GameType';
import { LocalStorageSave } from '../../../../../Services/Save/LocalStorageSave';
import { Star } from '../../Svg/Star/Star';
import { NavigationButton } from '../NavigationButton/NavigationButton';

interface ArchiveGameButtonProps {
  game: DGGame;
}

export const ArchiveGameButton: FunctionComponent<ArchiveGameButtonProps> = ({ game }) => {
  const localStorage: LocalStorageSave = new LocalStorageSave();

  function url(): string {
    return `/${game.type}?id=${game.id}`;
  }

  function gameDateFormat(): string {
    return format((game.data as any).date, 'd MMMM yyyy');
  }

  function subtitle(): string {
    switch (game.type) {
      case GameType.daily:
        return 'Image du jour';
      case GameType.theme:
        return 'Thème du jour';
    }
  }

  function hasWon(): boolean {
    return localStorage.hasWonGameWithId(game.id);
  }

  return (
    <NavigationButton navigation={url()}>
      <div className="items-center justify-between w-full pr-4 h-stack">
        <div className="items-start v-stack">
          <div>{gameDateFormat()}</div>
          <div className="text-secondary">{subtitle()}</div>
        </div>

        {hasWon() && (
          <div className="w-6 h-6 fill-yellow-400">
            <Star />
          </div>
        )}
      </div>
    </NavigationButton>
  );
};
