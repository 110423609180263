/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';

const completeTutorial = createAction<boolean>('tutorial/completedTutorial');
const setHasCompletedTutorial = createAction<boolean>('tutorial/setHasCompletedTutorial');

export default {
  completeTutorial,
  setHasCompletedTutorial
};
