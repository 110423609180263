/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { storeSelector } from '../Global/StoreSelector';

const state = createSelector(storeSelector, (state) => state.game.state);
const submitState = createSelector(storeSelector, (state) => state.game.submitState);

const game = createSelector(storeSelector, (state) => state.game.current);

const fieldValue = createSelector(storeSelector, (state) => state.game.fieldValue);
const words = createSelector(storeSelector, (state) => state.game.words);

const hasWon = createSelector(storeSelector, (state) => state.game.hasWon);

export default {
  state,
  submitState,
  game,
  fieldValue,
  words,
  hasWon
};
