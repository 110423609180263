import ReactGA, { EventArgs } from 'react-ga';
import { Optional } from '../../Utils/Types/Optional';
import { Analytics } from './Analytics';

export class GoogleAnalytics implements Analytics {
  public configure(): void {
    const trackingId: Optional<string> = process.env.REACT_APP_ANALYTICS_TRACKING_ID;
    if (trackingId) {
      ReactGA.initialize(trackingId);
    } else {
      console.error('Failed to configure analytics.');
    }
  }

  public trackNavigation(): void {
    ReactGA.pageview(window.location.pathname);
  }

  public trackEvent(event: EventArgs): void {
    ReactGA.event(event);
  }
}
