/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameHint } from '../../../Models/Enums/GameHint';

const resetGameHints = createAction('gameHint/resetGameHints');
const removeGameHint = createAction<GameHint>('gameHint/removeGameHint');
const applyHintOnWord = createAction<DGWord>('gameHint/applyHintOnWord');
const selectGameHint = createAction<GameHint>('gameHint/selectGameHint');
const deselectGameHint = createAction('gameHint/deselectGameHint');

export default {
  resetGameHints,
  removeGameHint,
  applyHintOnWord,
  selectGameHint,
  deselectGameHint
};
