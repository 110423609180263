import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { GameState } from '../Game/GameState';
import ThemeGameAction from './ThemeGameAction';

function addBuilderThemeGameAction<T extends GameState>(builder: ActionReducerMapBuilder<T>): void {
  builder.addCase(ThemeGameAction.setImageState, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      imageState: action.payload
    }
  }));
}

export default {
  addBuilderThemeGameAction
};
