import { nanoid } from 'nanoid';
import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DGRound } from '../../../../Models/App/DGRound';
import { Direction } from '../../../../Models/Enums/Direction';
import { GameType } from '../../../../Models/Enums/GameType';
import GameSelector from '../../../../Services/Redux/Game/GameSelector';
import ThemeGameWinSelector from '../../../../Services/Redux/ThemeGameWin/ThemeGameWinSelector';
import { Optional } from '../../../../Utils/Types/Optional';
import { NavigationButton } from '../../Components/Buttons/NavigationButton/NavigationButton';
import { Confetti } from '../../Components/Confetti/Confetti';
import { PageLayout } from '../../Components/Layouts/PageLayout/PageLayout';
import { VerticalToHorizontalLayout } from '../../Components/Layouts/VerticalToHorizontalLayout/VerticalToHorizontalLayout';
import { RoundGauge } from '../../Components/RoundGauge/RoundGauge';
import { ShareStack } from '../../Components/ShareStack/ShareStack';
import { WikiButton } from '../../Components/WikiButton/WikiButton';

export const ThemeGameWinPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const winImageUrls: string[] = useSelector(ThemeGameWinSelector.imageUrls);
  const theme: string = useSelector(ThemeGameWinSelector.theme);
  const wikiUrl: Optional<string> = useSelector(ThemeGameWinSelector.wikiUrl);
  const rounds: DGRound[] = useSelector(ThemeGameWinSelector.rounds);
  const hasWon: boolean = useSelector(GameSelector.hasWon);

  useEffect(() => {
    if (!hasWon) {
      navigate('/theme');
    }
  }, [hasWon]);

  return (
    <PageLayout>
      {hasWon ? (
        <VerticalToHorizontalLayout>
          <Confetti />

          <div className="items-center justify-center v-stack">
            <div className="grid w-full max-w-xl grid-cols-2 gap-3">
              {winImageUrls.map((imageUrl: string) => (
                <img key={imageUrl} className="w-full max-w-md rounded-md shadow-xl" src={imageUrl} alt="" />
              ))}
            </div>
          </div>

          <div className="items-center justify-center v-stack">
            <div className="items-center w-full max-w-md space-y-8 v-stack">
              <div className="space-y-2 text-center">
                <h1 className="text-title">Trouvé !</h1>
                <WikiButton text={theme} url={wikiUrl} />
              </div>

              <div className="items-center w-full space-y-2 v-stack">
                <div className="w-full space-y-1">
                  {rounds.map((round) => (
                    <RoundGauge key={nanoid()} round={round} />
                  ))}
                </div>
                <ShareStack gameType={GameType.daily} />
              </div>

              <div className="items-center w-full space-x-4 h-stack">
                <NavigationButton className="grow" navigation="/" direction={Direction.left}>
                  <div className="w-[100px] text-right ml-auto">Retour</div>
                </NavigationButton>

                <div className="w-px h-10 bg-neutral-300 dark:bg-neutral-700"></div>

                <NavigationButton className="grow" navigation="/archives">
                  <div className="w-[100px] text-left">Continuer</div>
                </NavigationButton>
              </div>
            </div>
          </div>
        </VerticalToHorizontalLayout>
      ) : (
        <></>
      )}
    </PageLayout>
  );
};
