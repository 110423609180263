import { plainToInstance } from 'class-transformer';
import { DGGame } from '../../../Models/App/Game/DGGame';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { State } from '../State/State';
import { GameData } from './Data/GameData';

export type GameState = State & {
  submitState: State;
  current?: DGGame;
  data?: GameData;
  fieldValue: string;
  words: DGWord[];
  inputHistory: string[];
  inputCount: number;
  hasWon: boolean;
};

export function gameStateFromSave(save: any): GameState {
  return {
    state: save.state,
    error: save.error,
    submitState: save.submitState,
    current: save.current,
    data: save.data,
    fieldValue: save.fieldValue,
    words: save.words.map((word: any) => plainToInstance(DGWord, word)),
    inputHistory: save.inputHistory,
    inputCount: save.inputCount,
    hasWon: save.hasWon
  };
}
