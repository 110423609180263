/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { concat, filter, map, mergeMap, Observable } from 'rxjs';
import { GameType } from '../../../Models/Enums/GameType';
import { Language } from '../../../Models/Enums/Language';
import { GameServer } from '../../Api/Game/GameServer';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import { preferredLanguage } from '../Preferences/Utils/PreferencesUtils';
import StateAction from '../State/StateAction';
import ArchivesAction from './ArchivesAction';

function fetchGame$(
  action$: Observable<AnyAction>,
  state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(ArchivesAction.fetchGames.match),
    mergeMap(() =>
      concat(
        StateAction.setLoading(ArchivesAction.statePrefix),
        fetchArchivesGameData$(
          dependencies.gameServer!,
          state$.value.archives.gameType,
          preferredLanguage(state$.value)
        ),
        StateAction.setData(ArchivesAction.statePrefix)
      )
    )
  );
}

function fetchArchivesGameData$(gameServer: GameServer, gameType: GameType, language: Language): Observable<AnyAction> {
  switch (gameType) {
    case GameType.daily:
      return gameServer.getAllDailyGames$(language).pipe(map((games) => ArchivesAction.setGames(games)));
    case GameType.theme:
      return gameServer.getAllThemeGames$(language).pipe(map((games) => ArchivesAction.setGames(games)));
  }
}

export default combineEpics(fetchGame$);
