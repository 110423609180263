import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

interface Props {
  isSelectingHintWord?: boolean;
  onClick?: () => void;
}

export const WordContainer: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  isSelectingHintWord = false,
  onClick
}) => {
  return (
    <div
      className={classNames(
        'rounded-md',
        isSelectingHintWord
          ? 'bg-blue-200 dark:bg-blue-500 cursor-pointer animate-pulse hover:bg-orange-300'
          : 'bg-tertiary dark:bg-secondary'
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
