/* eslint-disable @typescript-eslint/typedef */
import { createReducer } from '@reduxjs/toolkit';
import { Language } from '../../../Models/Enums/Language';
import PreferencesAction from './PreferencesAction';
import { PreferencesState } from './PreferencesState';

const initialState: PreferencesState = {
  language: Language.french
};
export const preferencesReducer = createReducer<PreferencesState>(initialState, (builder) => {
  builder.addCase(PreferencesAction.setLanguage, (state, action) => ({
    ...state,
    language: action.payload
  }));
});
