import React, { FunctionComponent } from 'react';

interface SidebarProps {
  children: [React.ReactNode, React.ReactNode];
}

export const SidebarLayout: FunctionComponent<SidebarProps> = ({ children }) => {
  return (
    <div className="grow h-stack">
      <div className="w-full md:max-w-md xl:max-w-none xl:w-2/5 min-h-screen-safe">{children[0]}</div>

      <div className="hidden md:v-stack grow min-h-screen-safe">{children[1]}</div>
    </div>
  );
};
