/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { GameHint } from '../../../Models/Enums/GameHint';
import { storeSelector } from '../Global/StoreSelector';

const isRevealFirstLetterAvailable = createSelector(storeSelector, (state) =>
  state.gameHint.availableHints.includes(GameHint.revealFirstLetter)
);
const isSelectingWord = createSelector(storeSelector, (state) => state.gameHint.selectedHint !== undefined);

export default {
  isRevealFirstLetterAvailable,
  isSelectingWord
};
