/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { plainToInstance } from 'class-transformer';
import { DGGameDailyData } from '../../../Models/App/Game/DGGameDailyData';
import { Optional } from '../../../Utils/Types/Optional';
import { storeSelector } from '../Global/StoreSelector';
import { StoreState } from '../Global/StoreState';

const imageText = createSelector(storeSelector, (state) => {
  const dailyGame: Optional<DGGameDailyData> = dailyGameFromState(state);
  return dailyGame?.image?.description ?? '';
});
const imageUrl = createSelector(storeSelector, (state) => {
  const dailyGame: Optional<DGGameDailyData> = dailyGameFromState(state);
  return dailyGame?.image?.url ?? '';
});
const round = createSelector(storeSelector, (state) => state.round.rounds[0]);

function dailyGameFromState(state: StoreState): Optional<DGGameDailyData> {
  const gameData: Optional<DGGameDailyData> = state.game.current?.data as DGGameDailyData;
  if (!gameData) {
    return undefined;
  }
  return plainToInstance(DGGameDailyData, gameData);
}

export default {
  imageText,
  imageUrl,
  round
};
