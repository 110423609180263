import { ToAppModelConvertible } from 'model-conversion';
import { DGWordSuggestion } from '../../App/Word/DGWordSuggestion';

export class ApiWordSuggestion implements ToAppModelConvertible<DGWordSuggestion> {
  public readonly text: string;
  public readonly similarity: number;

  public constructor(text: string, similarity: number) {
    this.text = text;
    this.similarity = similarity;
  }

  public static fromJson(json: any): ApiWordSuggestion {
    return new ApiWordSuggestion(json.text, json.similarity);
  }

  public toAppModel(): DGWordSuggestion {
    return new DGWordSuggestion(this.text, this.similarity);
  }
}
