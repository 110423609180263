/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { DGGameThemeData } from '../../../Models/App/Game/DGGameThemeData';
import { Optional } from '../../../Utils/Types/Optional';
import { GameThemeData } from '../Game/Data/GameThemeData';
import { storeSelector } from '../Global/StoreSelector';

const imageUrls = createSelector(storeSelector, (state) => {
  const data: Optional<GameThemeData> = state.game.data as GameThemeData;
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  if (!data || !gameData) {
    return [];
  }
  return [gameData.image1.url, gameData.image2.url, gameData.image3.url, gameData.image4.url];
});
const theme = createSelector(storeSelector, (state) => {
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  return gameData?.theme ?? '';
});
const wikiUrl = createSelector(storeSelector, (state) => {
  const gameData: Optional<DGGameThemeData> = state.game.current?.data as DGGameThemeData;
  return gameData?.referenceUrl ?? '';
});
const rounds = createSelector(storeSelector, (state) => state.round.rounds);

export default {
  imageUrls,
  theme,
  wikiUrl,
  rounds
};
