/* eslint-disable @typescript-eslint/typedef */
import { createSelector } from '@reduxjs/toolkit';
import { storeSelector } from '../Global/StoreSelector';

const gameType = createSelector(storeSelector, (state) => state.archives.gameType);
const games = createSelector(storeSelector, (state) => state.archives.games);

export default {
  gameType,
  games
};
