/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';
import { DGWord } from '../../../Models/App/Word/DGWord';
import { GameHint } from '../../../Models/Enums/GameHint';

const addInput = createAction<{ input: string; words: DGWord[] }>('round/addInput');
const addUsedHint = createAction<GameHint>('round/addUsedHint');

export default {
  addInput,
  addUsedHint
};
