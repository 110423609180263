import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { DGRound } from '../../../../Models/App/DGRound';
import { GameType } from '../../../../Models/Enums/GameType';
import RoundSelector from '../../../../Services/Redux/Round/RoundSelector';
import { DGRoundScoreCalculator } from '../../../../Services/RoundScoreCalculator/DGRoundScoreCalculator';
import { DGShareTextBuilder } from '../../../../Services/ShareTextBuilder/DGShareTextBuilder';
import { ClipboardButton } from '../Buttons/ClipboardButton/ClipboardButton';
import { TwitterButton } from '../Buttons/TwitterButton/TwitterButton';

interface ShareStackProps {
  gameType: GameType;
}

export const ShareStack: FunctionComponent<ShareStackProps> = ({ gameType }) => {
  const rounds: DGRound[] = useSelector(RoundSelector.rounds);
  const scoreCalculator: DGRoundScoreCalculator = new DGRoundScoreCalculator();
  const textBuilder: DGShareTextBuilder = new DGShareTextBuilder(scoreCalculator);
  const text: string = textBuilder.buildText(gameType, rounds, 13);
  const url: string = textBuilder.url();

  return (
    <div className="h-stack items-center">
      <ClipboardButton text={`${text}\n${url}`} />
      <TwitterButton gameType={gameType} />
    </div>
  );
};
