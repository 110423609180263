import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { DGImage } from '../../../../../../../Models/App/DGImage';
import { ThemeGameImageState } from '../../../../../../../Services/Redux/ThemeGame/Models/ThemeGameImageState';
import ThemeGameSelector from '../../../../../../../Services/Redux/ThemeGame/ThemeGameSelector';
import { Optional } from '../../../../../../../Utils/Types/Optional';
import { GameFlipImage } from '../../../../GameFlipImage/GameFlipImage';
import { Case } from '../../../../Switch/Case';
import { Switch } from '../../../../Switch/Switch';
import { ThemeGuessedImages } from '../ThemeGuessedImage/ThemeGuessedImage';

export const ThemeImage: FunctionComponent = () => {
  const imageState: ThemeGameImageState = useSelector(ThemeGameSelector.imageState);
  const currentImageUrl: Optional<string> = useSelector(ThemeGameSelector.currentImageUrl);
  const guessedImages: DGImage[] = useSelector(ThemeGameSelector.guessedImages);

  return (
    <Switch value={imageState}>
      <Case value={ThemeGameImageState.theme}>
        <div className="items-center space-y-4 text-lg v-stack lg:text-xl">
          <div className="hidden text-center lg:v-stack">Quel est le point commun entre ces images ?</div>

          <div className="grid w-full max-w-xl grid-cols-2 gap-3">
            {guessedImages.map((image: DGImage) => (
              <img key={image.url} className="w-full max-w-md rounded-md shadow-xl" src={image.url} alt="" />
            ))}
          </div>

          <div className="text-center lg:hidden">Quel est le point commun entre ces images ?</div>
        </div>
      </Case>

      <Case value="*">
        <div className="items-center w-full space-y-4 v-stack">
          <div className="lg:hidden">
            <ThemeGuessedImages guessedImageUrls={guessedImages.map((image) => image.url)} />
          </div>

          <div className="items-center justify-center w-full v-stack">
            <GameFlipImage className="w-full" src={currentImageUrl ?? ''} />
          </div>

          <div className="hidden lg:v-stack">
            <ThemeGuessedImages guessedImageUrls={guessedImages.map((image) => image.url)} />
          </div>
        </div>
      </Case>
    </Switch>
  );
};
