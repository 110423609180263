import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, map, Observable, tap } from 'rxjs';
import Action from '../../../Utils/Action';
import { mapPayload } from '../../../Utils/RxJs/MapPayload';
import { StoreDependencies } from '../Global/StoreDependencies';
import { StoreState } from '../Global/StoreState';
import AnalyticsAction from './AnalyticsAction';

function configure$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(Action.start.match),
    tap(() => dependencies.analytics?.configure()),
    map(() => Action.empty())
  );
}

function trackNavigation$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(AnalyticsAction.trackNavigation.match),
    tap(() => dependencies.analytics?.trackNavigation()),
    map(() => Action.empty())
  );
}

function trackEvent$(
  action$: Observable<AnyAction>,
  _state$: StateObservable<StoreState>,
  dependencies: Partial<StoreDependencies>
): Observable<AnyAction> {
  return action$.pipe(
    filter(AnalyticsAction.trackEvent.match),
    mapPayload(),
    tap((event) => dependencies.analytics?.trackEvent(event)),
    map(() => Action.empty())
  );
}

export default combineEpics(configure$, trackNavigation$, trackEvent$);
