import { FunctionComponent, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SubmitState } from '../../../../Models/Enums/SubmitState';
import InputHistorySelector from '../../../../Services/Redux/InputHistory/InputHistorySelector';
import RoundSelector from '../../../../Services/Redux/Round/RoundSelector';
import { Optional } from '../../../../Utils/Types/Optional';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import { Case } from '../Switch/Case';
import { Switch } from '../Switch/Switch';
import { TextField } from '../TextField/TextField';

interface SubmitFieldProps {
  value: string;
  state: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
}

export const SubmitField: FunctionComponent<SubmitFieldProps> = ({ value, state, onChange, onSubmit }) => {
  const inputRef = useRef<HTMLInputElement>();
  const inputCount: number = useSelector(RoundSelector.inputCount);
  const lastInput: Optional<string> = useSelector(InputHistorySelector.lastInput);

  function submit(): void {
    onSubmit(value);
    inputRef.current?.focus();
  }

  return (
    <div className="items-center w-full space-y-1 v-stack">
      <div className="items-start w-full v-stack">
        <div className="w-full h-12 overflow-hidden bg-tertiary dark:bg-secondary h-stack rounded-xl lg:h-14">
          <TextField
            className="w-full pl-4 border-transparent focus:border-transparent focus:ring-0 focus:outline-none lg:text-xl"
            reference={inputRef}
            value={value}
            placeholder={lastInput ?? 'Tapez des mots'}
            onChange={onChange}
            onSubmit={submit}
          />
          <div className="mt-1 mb-1 mr-1 lg:text-xl">
            <Switch value={state}>
              <Case value={'*'}>
                <SubmitButton className="h-full" onClick={submit} />
              </Case>
              <Case value={SubmitState.loading}>
                <SubmitButton className="h-full" disabled={true} onClick={submit} />
              </Case>
            </Switch>
          </div>
        </div>

        <div className="pt-2 pl-4 text-sm text-secondary">
          <div>
            {inputCount} {inputCount > 1 ? 'coups' : 'coup'}
          </div>
        </div>
      </div>

      <Switch value={state}>
        <Case value={SubmitState.error}>
          <div className="text-xs text-red-500">Une erreur est survenue. Veuillez réessayer.</div>
        </Case>
        <Case value={'*'}>
          <></>
        </Case>
      </Switch>
    </div>
  );
};
