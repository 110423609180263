import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import AnalyticsAction from '../Services/Redux/Analytics/AnalyticsAction';
import Action from '../Utils/Action';
import { AboutPage } from './UI/Pages/About/AboutPage';
import { ArchivePage } from './UI/Pages/Archives/ArchivesPage';
import { DailyGamePage } from './UI/Pages/DailyGame/DailyGamePage';
import { DailyGameWinPage } from './UI/Pages/DailyGameWin/DailyGameWinPage';
import { HomePage } from './UI/Pages/Home/HomePage';
import { ThemeGamePage } from './UI/Pages/ThemeGame/ThemeGamePage';
import { ThemeGameWinPage } from './UI/Pages/ThemeGameWinPage/ThemeGameWinPage';
import { TutorialPage } from './UI/Pages/Tutorial/TutorialPage';

export const App: FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Action.start());
  }, []);

  useEffect(() => {
    dispatch(AnalyticsAction.trackNavigation());
  }, [location]);

  return (
    <div className="min-h-screen-safe v-stack">
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="tutorial" element={<TutorialPage />} />
        <Route path="archives" element={<ArchivePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="daily">
          <Route path="" element={<DailyGamePage />} />
          <Route path="win" element={<DailyGameWinPage />} />
        </Route>
        <Route path="theme">
          <Route path="" element={<ThemeGamePage />} />
          <Route path="win" element={<ThemeGameWinPage />} />
        </Route>
      </Routes>
    </div>
  );
};
