import { ToApiModelConvertible } from 'model-conversion';
import { ApiWordSuggestion } from '../../Api/Word/ApiWordSuggestion';

export class DGWordSuggestion implements ToApiModelConvertible<ApiWordSuggestion> {
  public constructor(public readonly text: string, public readonly similarity: number) {}

  public toApiModel(): ApiWordSuggestion {
    return new ApiWordSuggestion(this.text, this.similarity);
  }
}
