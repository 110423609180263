import { FunctionComponent, useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { GameImage } from '../GameImage/GameImage';

interface GameFlipImageProps {
  className?: string;
  src: string;
  flipDelay?: number;
}

export const GameFlipImage: FunctionComponent<GameFlipImageProps> = ({ className, src, flipDelay = 1000 }) => {
  const allSentences: string[] = ["Qu'est ce que ça peut être ?", "Mais c'est quoi ça ?", "C'est quoi ce truc ?"];
  const [isFlipped, setIsFlipped] = useState(true);
  const [randomSentence, _setRandomSentence] = useState(allSentences[Math.floor(Math.random() * allSentences.length)]);

  useEffect(() => {
    const timeout = setTimeout(() => setIsFlipped(false), flipDelay);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ReactCardFlip containerClassName={className} isFlipped={isFlipped} flipDirection={'horizontal'}>
      <GameImage src={src} />
      <div className="w-full shadow-xl bg-secondary aspect-square rounded-xl">
        <div className="items-center justify-center h-full p-8 text-4xl font-bold leading-tight text-center text-tertiary v-stack text-opacity-40">
          {randomSentence}
        </div>
      </div>
    </ReactCardFlip>
  );
};
