/* eslint-disable @typescript-eslint/typedef */
import { createAction } from '@reduxjs/toolkit';

const start = createAction('action/start');
const empty = createAction('action/empty');

export default {
  start,
  empty
};
