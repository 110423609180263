import { FunctionComponent } from 'react';
import { allGameTypes, GameType } from '../../../../../../Models/Enums/GameType';
import { LocalStorageSave } from '../../../../../../Services/Save/LocalStorageSave';
import { GameButton } from '../../../../Components/Buttons/GameButton/GameButton';
import { ClearSaveButton } from '../../../../Components/ClearSaveButton/ClearSaveButton';
import { Divider } from '../../../../Components/Divider/Divider';
import { Footer } from '../../../../Components/Footer/Footer';
import { Logo } from '../../../../Components/Logo/Logo';
import { ArchivesButton } from '../ArchivesButton/ArchivesButton';
import { TutorialButton } from '../TutorialButton/TutorialButton';

export const Sidebar: FunctionComponent = () => {
  const isProduction: boolean = process.env.NODE_ENV === 'production';
  const localStorage: LocalStorageSave = new LocalStorageSave();

  function wonGameCountOfType(gameType: GameType): number {
    return localStorage.wonGameCountOfType(gameType);
  }

  return (
    <aside className="items-center justify-between w-full h-full p-4 v-stack">
      <div className="items-center pb-10 mt-auto v-stack">
        <Logo />
      </div>

      <div className="w-full max-w-lg mb-auto space-y-4">
        <div className="w-full px-4 py-3 mb-4 bg-secondary rounded-xl">
          <h3 className="text-xs text-yellow-300">Nouveau</h3>
          <h4 className="text-sm">
            Les indices 🃏 sont maintenant illimités et ont été améliorés ! Utilisez un indice 🃏 plusieurs fois sur un
            mot pour révéler plus de lettres.
          </h4>
        </div>

        <div className="items-center rounded-md v-stack">
          {allGameTypes.map((gameType) => (
            <GameButton key={gameType} className="w-full" gameType={gameType} winCount={wonGameCountOfType(gameType)} />
          ))}
        </div>

        <div className="mx-2">
          <Divider />
        </div>

        <div className="v-stack">
          <ArchivesButton />
          <TutorialButton />
        </div>

        {!isProduction && <ClearSaveButton />}
      </div>

      <Footer />
    </aside>
  );
};
