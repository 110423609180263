import { ToAppModelConvertible } from 'model-conversion';
import { DGWord } from '../../App/Word/DGWord';
import { WordState } from '../../Enums/WordState';
import { ApiWordSuggestion } from './ApiWordSuggestion';

export class ApiWord implements ToAppModelConvertible<DGWord> {
  public constructor(
    public readonly id: string,
    public readonly text: string,
    public readonly state: WordState,
    public readonly suggestion?: ApiWordSuggestion,
    public readonly revealedLetters: number = 0
  ) {}

  public static fromJson(json: any): ApiWord {
    return new ApiWord(
      json.id,
      json.text,
      json.state,
      json.suggestion ? ApiWordSuggestion.fromJson(json.suggestion) : undefined,
      json.revealedLetters
    );
  }

  public toAppModel(): DGWord {
    return new DGWord(this.id, this.text, this.state, this.suggestion?.toAppModel(), this.revealedLetters);
  }
}
