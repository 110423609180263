import { FunctionComponent } from 'react';
import { WordContainer } from './Components/WordContainer';
import { WordHiddenText } from './Components/WordHiddenText';
import { WordPrefix } from './Components/WordPrefix';
import { WordSuffix } from './Components/WordSuffix';

interface WordNotGuessedProps {
  text: string;
  isSelectingHintWord: boolean;
  onClick: () => void;
}

export const WordNotGuessed: FunctionComponent<WordNotGuessedProps> = ({ text, isSelectingHintWord, onClick }) => {
  return (
    <div className="h-stack">
      <WordPrefix text={text} />
      <WordContainer isSelectingHintWord={isSelectingHintWord} onClick={onClick}>
        <WordHiddenText text={text} />
      </WordContainer>
      <WordSuffix text={text} />
    </div>
  );
};
