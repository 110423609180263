import { FunctionComponent } from 'react';
import { AboutSection } from '../../Components/AboutSection/AboutSection';
import { PageLayout } from '../../Components/Layouts/PageLayout/PageLayout';

export const AboutPage: FunctionComponent = () => {
  return (
    <PageLayout>
      <div className="items-center justify-center w-full space-y-12 v-stack grow">
        <h1>À propos</h1>

        <div className="w-full max-w-lg space-y-8 text-center v-stack">
          <AboutSection title="Conception">
            <p>
              Conçu avec ❤️ par <a href="https://github.com/bpisano">bpisano</a> en 2022 par pur fun.
            </p>
          </AboutSection>

          <AboutSection title="Réseaux">
            <p>
              Suivez le compte <a href="https://twitter.com/dalle_guesser">Twitter Dall-e Guesser</a>.
            </p>
          </AboutSection>

          <AboutSection title="Vidéo">
            <p>
              Suivez le <a href="https://www.youtube.com/channel/UCP3ThHkpKKTDOjDNpzmYwsA">Devlog</a> sur YouTube.
            </p>
          </AboutSection>

          <AboutSection title="Données">
            <p>
              Données de <a href="https://fauconnier.github.io/#data">Jean-Philippe Fauconnier</a>.
            </p>
          </AboutSection>
        </div>
      </div>
    </PageLayout>
  );
};
