import { Store } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'reflect-metadata';
import { App } from './Application/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GoogleAnalytics } from './Services/Analytics/GoogleAnalytics';
import { ApiGameServer } from './Services/Api/Game/ApiGameServer';
import { ApiWordServer } from './Services/Api/Word/ApiWordServer';
import { createStore } from './Services/Redux/Global/StoreConfiguration';
import { StoreDependencies } from './Services/Redux/Global/StoreDependencies';
import { StoreState } from './Services/Redux/Global/StoreState';
import { LocalStorageSave } from './Services/Save/LocalStorageSave';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const storeDependencies: StoreDependencies = {
  gameServer: new ApiGameServer(),
  wordServer: new ApiWordServer(),
  saveSystem: new LocalStorageSave(),
  analytics: new GoogleAnalytics()
};
const store: Store<StoreState> = createStore(storeDependencies);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
