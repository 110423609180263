import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GameHint } from '../../../../Models/Enums/GameHint';
import GameHintAction from '../../../../Services/Redux/GameHint/GameHintAction';
import GameHintSelector from '../../../../Services/Redux/GameHint/GameHintSelector';
import { Button } from '../Buttons/Button/Button';

export const HintSimilarWordButton: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isRevealFirstLetterAvailable: boolean = useSelector(GameHintSelector.isRevealFirstLetterAvailable);
  const isSelectingWord: boolean = useSelector(GameHintSelector.isSelectingWord);

  function selectWord(): void {
    dispatch(GameHintAction.selectGameHint(GameHint.revealFirstLetter));
  }

  function cancel(): void {
    dispatch(GameHintAction.deselectGameHint());
  }

  return (
    <div className="items-center h-8 lg:h-12 h-stack">
      {isSelectingWord ? (
        <div className="text-sm v-stack">
          <p className="text-neutral-400">Sélectionnez le mot sur lequel vous souhaitez un indice</p>
          <button className="text-blue-500" onClick={cancel}>
            Annuler
          </button>
        </div>
      ) : isRevealFirstLetterAvailable ? (
        <Button
          icon={<div className="px-2 py-1 text-3xl lg:text-4xl drop-shadow-sm rotate-6">🃏</div>}
          padding={4}
          onClick={selectWord}
        />
      ) : (
        <div className="w-3 h-3 bg-gray-200 rounded-full dark:bg-neutral-800"></div>
      )}
    </div>
  );
};
