import { FunctionComponent, PropsWithChildren } from 'react';

interface AboutSectionProps {
  title: string;
}

export const AboutSection: FunctionComponent<PropsWithChildren<AboutSectionProps>> = ({ children, title }) => {
  return (
    <div>
      <h2>{title}</h2>
      {children}
    </div>
  );
};
